import {
  HIDE_ANIMATION,
  SHOW_ANIMATION,
  SKIP_ANIMATIONS,
} from '../actions/animateActions'

export const initialState = {
  animateShow: false,
  passedAnimations: {},
}

export const animateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ANIMATION: {
      return {
        ...state,
        animateShow: true,
      }
    }

    case HIDE_ANIMATION: {
      return {
        ...state,
        animateShow: false,
      }
    }

    case SKIP_ANIMATIONS: {
      const passedAnimations = action.payload.types.reduce(
        (passed, type) => ({
          ...passed,
          [type]: true,
        }),
        state.passedAnimations
      )
      localStorage.setItem(
        'passedAnimations',
        JSON.stringify(Object.keys(passedAnimations))
      )
      return {
        ...state,
        passedAnimations,
        animateShow: false,
      }
    }

    default: {
      return state
    }
  }
}
