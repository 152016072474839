import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Loader } from "@components/loader/loader";

import classes from "./customButtonHeader.module.scss";

const CustomButton = ({ label, icon, onClick, editing, loading }) => {
  return (
    <button
      className={classNames(classes.container, {
        [classes.editingButton]: editing,
      })}
      onClick={onClick}
    >
      {loading ? (
        <Loader type="headerButton" testId="custom-buttom-loader" />
      ) : (
        <>
          {label} <img src={icon} alt={label} />
        </>
      )}
    </button>
  );
};

CustomButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  editing: PropTypes.bool,
  loading: PropTypes.bool,
};

export default CustomButton;
