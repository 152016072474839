import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import classes from "./headerProductMenu.module.scss";
import PopupWrapper from "@components/common/popupWrapper/popupWrapper";
import CustomButton from "@components/customButton/customButton";

const ConfirmAddToBundle = ({ onClose, onAddToCart, onCancel, onDiscard }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return (
    <PopupWrapper
      afterClose={onClose}
      innerPopupClass={classes.innerPopupClasses}
    >
      <div className={classes.titlePopup}>
        Do you want to add bundle to the cart?
      </div>
      <div className={classes.buttonsRowPopup}>
        <CustomButton
          onClick={onAddToCart}
          width={isDesktop ? "170px" : null}
          height="40px"
          label="ADD TO CART"
        />
        <div onClick={onDiscard} className={classes.discardButton}>
          DISCARD BUNDLE
        </div>
      </div>
      <div onClick={onCancel} className={classes.cancelLink}>
        CANCEL
      </div>
    </PopupWrapper>
  );
};

ConfirmAddToBundle.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmAddToBundle;
