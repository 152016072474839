import React from "react";
import PropTypes from "prop-types";
import ProductsTab from "@components/productsTab/productsTab";

import classes from "./productsTabs.module.scss";

const ProductsTabs = ({ items, setActiveItem, activeItem, isImageSet }) => {
  return (
    <ul className={classes.product__nav} data-testid="product-tabs">
      {items.map((item, index) => (
        <ProductsTab
          key={item.name}
          item={item}
          activeItem={activeItem}
          index={index}
          setActiveItem={setActiveItem}
          isDisable={index === 0 ? true : isImageSet}
        />
      ))}
    </ul>
  );
};

ProductsTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.number,
    })
  ).isRequired,
  setActiveItem: PropTypes.func.isRequired,
  activeItem: PropTypes.number,
  isImageSet: PropTypes.string,
};

ProductsTabs.defaultProps = {
  activeItem: null,
  isImageSet: undefined,
};

export default ProductsTabs;
