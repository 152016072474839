import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import classes from "../productsMenu/productsMenu.module.scss";
import {
  changeAdditionalInformationIndex,
  openPopup,
} from "../../redux/actions/productsActions";
import ProductInformation from "../productInformation/productInformation";

const Product = ({
  product,
  index,
  primaryChoice,
  clickHandler,
  isPopupState,
  additionalInformationIndex,
  mouseDownHandler,
  touchStartHandler,
  active,
  count,
  innerRef,
  onAddToBundle,
  onShowProductInfo,
  isDesktop,
  testId
}) => {
  const dispatch = useDispatch();

  const hadleShowProductInfo = (evt) => {
    evt.stopPropagation();
    dispatch(openPopup());
    dispatch(changeAdditionalInformationIndex(index));
    if (onShowProductInfo) {
      onShowProductInfo();
    }
  };

  const handleClick = (evt) => {
    if (isDesktop) {
      clickHandler(evt);
    } else {
      hadleShowProductInfo(evt);
    }
  };

  return (
    <li
      className={classes.product__tabItem}
      key={product.id}
      ref={innerRef}
      data-testid={testId}
    >
      <button
        type="button"
        className={classes.product__tabZoom}
        aria-label="zoom product item"
        data-testid="button-zoom"
        onClick={hadleShowProductInfo}
      />
      <button
        type="button"
        className={classNames(classes.product__tabLink, {
          [classes.product__tabLink_active]: primaryChoice === index || active,
          [classes.product__tabLink_active__count]: !!count,
        })}
        onClick={handleClick}
        onMouseDown={mouseDownHandler}
        onTouchStart={touchStartHandler}
        data-testid="button-product-default"
      >
        <span className={classes.product__tabLinkContainer}>
          <span className={classes.product__tabImg}>
            <span className="img__container">
              <img
                src={product.image.primary}
                alt="Product"
                draggable="false"
              />
            </span>
          </span>
          <span className={classes.product__tabCaption}>{product.name}</span>
          <span className={classes.product__tabPrice}>
            <span>$</span>
            {product.price}
          </span>
        </span>
        {!!count && (
          <div className={classes.product__tabItemCount}>
            <span>{count}</span>
          </div>
        )}
      </button>
      {isPopupState && additionalInformationIndex === index && (
        <ProductInformation
          description={product.additional.description}
          dimensions={product.additional.dimensions}
          image={product.additional.image}
          images={product.image.carousel}
          model={product.additional.model}
          name={product.additional.name}
          straps={product.additional.straps}
          warranty={product.additional.warranty}
          weight={product.additional.weight}
          isPopupState={isPopupState}
          price={product.price}
          onAddToBundle={onAddToBundle}
          isDesktop={isDesktop}
        />
      )}
    </li>
  );
};

Product.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  primaryChoice: PropTypes.number,
  clickHandler: PropTypes.func,
  isPopupState: PropTypes.bool.isRequired,
  additionalInformationIndex: PropTypes.number,
  mouseDownHandler: PropTypes.func,
  touchStartHandler: PropTypes.func,
  active: PropTypes.bool,
  count: PropTypes.number,
  innerRef: PropTypes.any,
  onAddToBundle: PropTypes.func.isRequired,
  onShowProductInfo: PropTypes.func,
  testId: PropTypes.string,
  isDesktop: PropTypes.bool,
  type: PropTypes.oneOf(["product", "pouche", "accessory"]),
};

Product.defaultProps = {
  primaryChoice: null,
  additionalInformationIndex: null,
  mouseDownHandler: () => {},
  touchStartHandler: () => {},
  clickHandler: () => {},
  active: false,
  count: null,
};

export default Product;
