export function isPossibleToDrop(cells, index, size) {
  if (cells[index]) {
    return false
  }

  let numberOfFreeCells = 1
  for (let i = index + 1; i < cells.length; i += 1) {
    if (!cells[i]) {
      numberOfFreeCells += 1
    } else {
      break
    }
  }

  for (let i = index - 1; i >= 0; i -= 1) {
    if (!cells[i]) {
      numberOfFreeCells += 1
    } else {
      break
    }
  }

  return numberOfFreeCells >= size
}

export function droppedIndex(cells, index, size) {
  if (cells[index] || !isPossibleToDrop(cells, index, size)) {
    return -1
  }

  if (size === 1) {
    return index
  }

  let rightFreeCellsNumber = 1
  for (let i = index + 1; i < cells.length; i += 1) {
    if (!cells[i]) {
      rightFreeCellsNumber += 1
    } else {
      break
    }
  }

  let leftFreeCellsNumber = 0
  for (let i = index - 1; i >= 0; i -= 1) {
    if (!cells[i]) {
      leftFreeCellsNumber += 1
    } else {
      break
    }
  }

  if (leftFreeCellsNumber >= Math.ceil(size / 2)) {
    if (rightFreeCellsNumber <= Math.floor(size / 2)) {
      return index + rightFreeCellsNumber - size
    }
    return index - Math.ceil(size / 2)
  }

  return index - leftFreeCellsNumber
}

export function pointInRect(point, rect) {
  return (
    point.x >= rect.x &&
    point.x <= rect.x + rect.width &&
    point.y >= rect.y &&
    point.y <= rect.y + rect.height
  )
}

export function pointIndex(point, rect, count) {
  if (!pointInRect(point, rect)) {
    return -1
  }
  return Math.floor(((point.x - rect.x) / rect.width) * count)
}

export function createCells(draggedProducts, shapeCount, dragItemIndex = null) {
  const cells = new Array(shapeCount).fill(null)
  for (let i = 0; i < cells.length; i += 1) {
    if (draggedProducts[i]) {
      cells[i] = {
        ...draggedProducts[i],
        id: `#id-${draggedProducts[i].item.id}-${i}`,
        free: false,
        active: false,
        possible: false,
      }
      for (let j = i + 1; j < i + draggedProducts[i].size; j += 1) {
        cells[j] = {
          id: `#index-${j}`,
          size: 1,
          item: null,
          url: null,
          free: false,
          active: false,
          possible: false,
        }
      }
      i += draggedProducts[i].size - 1
    } else {
      cells[i] = {
        id: `#index-${i}`,
        size: 1,
        item: null,
        url: null,
        free: true,
        active: false,
        possible: false,
      }
    }
  }

  if (dragItemIndex !== null && draggedProducts[dragItemIndex]) {
    for (
      let i = dragItemIndex;
      i < dragItemIndex + draggedProducts[dragItemIndex].size;
      i += 1
    ) {
      cells[i].free = true
    }
  }

  return cells
}

export function possibleToDrop(
  draggedProducts,
  shapeCount,
  dragProduct,
  dragItemIndex,
) {
  const cells = new Array(shapeCount).fill(false)
  const indexMap = {}

  for (let i = 0; i < shapeCount; i += 1) {
    if (i !== dragItemIndex && draggedProducts[i]) {
      cells[i] = true
      for (let j = i + 1; j < i + draggedProducts[i].size; j += 1) {
        cells[j] = true
      }
      i += draggedProducts[i].size - 1
    }
  }

  for (let i = 0; i < shapeCount; i += 1) {
    if (dragProduct && isPossibleToDrop(cells, i, dragProduct.shapeSize)) {
      const index = droppedIndex(cells, i, dragProduct.shapeSize)
      indexMap[i] = new Array(dragProduct.shapeSize)
        .fill(0)
        .map((_, t) => index + t)
    }
  }

  return indexMap
}

export function maxSpaceSize(cells) {
  let max = 0
  let current = 0
  for (let i = 0; i < cells.length; i += 1) {
    if (cells[i]) {
      current += 1
    } else {
      max = Math.max(max, current)
      current = 0
    }
  }
  return Math.max(max, current)
}

export function distance(start, end) {
  return Math.sqrt((start.x - end.x) ** 2 + (start.y - end.y) ** 2)
}

export function isVertical(start, end) {
  return Math.abs(start.x - end.x) < start.y - end.y || start.y - end.y > 100
}

export function debounce(callback, wait) {
  let timeoutId
  let next
  return (...args) => {
    if (timeoutId) {
      next = () => {
        callback(...args)
      }
      return
    }

    timeoutId = setTimeout(() => {
      if (next) {
        next()
      }
      next = null
      timeoutId = null
    }, wait)
    callback(...args)
  }
}

export function freeSpaceIndex(draggedProducts, shapeCount, size) {
  let spaceSize = 0

  for (let i = 0; i < shapeCount; i += 1) {
    if (!draggedProducts[i]) {
      spaceSize += 1
      if (spaceSize === size) {
        return i - size + 1
      }
    } else {
      spaceSize = 0
      i += draggedProducts[i].size - 1
    }
  }

  return -1
}

function getImage(src) {
  const rand = Math.random()
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = `${src}?${rand}`
    img.crossOrigin = 'Anonymous'
    img.addEventListener('load', () => {
      resolve(img)
    })
    img.addEventListener('error', reject)
  })
}

export async function createKneePadsCanvas(items, size) {
  const width = 1280
  const height = 720

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const images = await Promise.all(items.map(item => getImage(item.src).then(img => ({ img, rect: item }))))

  const context2D = canvas.getContext('2d')

  context2D.fillStyle = 'white'
  context2D.fillRect(0, 0, width, height)

  if (size.width / size.height > width / height) {
    images.forEach(({ img, rect }) => {
      context2D.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        rect.x / size.width * width,
        height / 2 - size.height / size.width * width / 2 + rect.y / size.width * width,
        rect.width / size.width * width,
        rect.height / size.width * width,
      )
    })
  } else {
    images.forEach(({ img, rect }) => {
      context2D.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        width / 2 - size.width / size.height * height / 2 + rect.x / size.height * height,
        rect.y / size.height * height,
        rect.width / size.height * height,
        rect.height / size.height * height,
      )
    })
  }
  return canvas.toDataURL('image/jpeg', 1.0)
}

export async function createCanvas(primary, dragged, fixed, basket) {
  const width = 720
  const height = 1280

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const primaryImage = await getImage(primary.src)
  const context2D = canvas.getContext('2d')

  const h = (primaryImage.height / primaryImage.width) * width
  const y = height / 2 - h / 2

  context2D.fillStyle = 'white'
  context2D.fillRect(0, 0, width, height)

  context2D.drawImage(
    primaryImage,
    0,
    0,
    primaryImage.width,
    primaryImage.height,
    0,
    y,
    width,
    h,
  )

  if (fixed) {
    const fixedImage = await getImage(fixed.src)
    const ix = ((fixed.rect.x - primary.rect.x) / primary.rect.width) * width
    const iy =
      y + ((fixed.rect.y - primary.rect.y) / primary.rect.width) * width

    const iw = (fixed.rect.width / primary.rect.width) * width
    const ih = (fixedImage.height / fixedImage.width) * iw

    context2D.drawImage(
      fixedImage,
      0,
      0,
      fixedImage.width,
      fixedImage.height,
      ix,
      iy,
      iw,
      ih,
    )
  }

  const items = await Promise.all(
    dragged.map(({ src, rect }) =>
      getImage(src)
        .then((img) => ({ img, rect }))
        .catch(() => null),
    ),
  )

  items.filter(Boolean).forEach(({ rect, img }) => {
    const ix = ((rect.x - primary.rect.x) / primary.rect.width) * width
    const iy = y + ((rect.y - primary.rect.y) / primary.rect.width) * width

    const iw = (rect.width / primary.rect.width) * width
    const ih = (img.height / img.width) * iw

    context2D.drawImage(img, 0, 0, img.width, img.height, ix, iy, iw, ih)
  })

  const basketItems = await Promise.all(
    basket.map(({ src }) =>
      getImage(src)
        .then((img) => ({ img }))
        .catch(() => null),
    ),
  ).then(e => e.filter(Boolean))

  const size = width / Math.max(5, basketItems.length)

  basketItems.forEach(({ img }, i) => {
    const ix = i * size
    const iy = height - size

    const imageHeight = img.height / Math.max(img.height, img.width) * size
    const imageWidth = img.width / Math.max(img.height, img.width) * size

    context2D.drawImage(img, 0, 0, img.width, img.height, ix, iy, imageWidth, imageHeight)
  })

  return canvas.toDataURL('image/jpeg', 1.0)
}
