import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import classes from "./productInformation.module.scss";
import ArrowLeft from "../../assets/images/icon/arrow_left_black.svg";
import ArrowRight from "../../assets/images/icon/arrow_right_black.svg";

const Carousel = ({ images, additional }) => {
  const [selected, setSelected] = useState(0);

  return (
    <div className={classes.carousel__container}>
      {images && images.length > 0 ? (
        <div className={classes.carousel__selected__image}>
          <img
            className={classes.carousel__arrow__control}
            src={ArrowLeft}
            alt=""
            data-testid="arrow-prev"
            onClick={() => selected > 0 && setSelected((prev) => prev - 1)}
          />
          <img
            alt=""
            src={images[selected]}
            data-testid="selected-image"
            className={classes.image__selected}
          />
          <img
            className={classes.carousel__arrow__control}
            src={ArrowRight}
            alt=""
            data-testid="arrow-next"
            onClick={() =>
              selected < images.length - 1 && setSelected((prev) => prev + 1)
            }
          />
        </div>
      ) : (
        <div className={classes.carousel__selected__image}>
          <img
            alt=""
            src={additional}
            data-testid="selected-image"
            className={classes.image__selected}
          />
        </div>
      )}

      <div
        className={classes.carousel__option_images}
        data-testid="option-list"
      >
        {images &&
          images.map((image, index) => (
            <div
              className={classNames(classes.carousel__option, {
                [classes.carousel__option__selected]: index === selected,
              })}
              onClick={() => setSelected(index)}
              key={`image-${index}`}
            >
              <img src={image} alt="" />
            </div>
          ))}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  additional: PropTypes.string,
};

export default Carousel;
