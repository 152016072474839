import React from 'react'
import Tooltip from '../tooltip'

const FirstTooltipDesktop = () => {
  return (
    <Tooltip
      corner='right'
      position={{
        top: '175px',
        right: '450px',
      }}
      title='Select product'
      content='Drag and drop it anywhere on the belt.'
    />
  )
}

export default FirstTooltipDesktop
