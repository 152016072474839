import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import classes from "./productsTab.module.scss";

const ProductsTab = ({ item, setActiveItem, index, activeItem, isDisable }) => {
  return (
    <li className={classes.product__navItem}>
      <button
        type="button"
        onClick={(evt) => {
          evt.preventDefault();
          setActiveItem(index);
        }}
        className={classNames(classes.product__navLink, {
          [classes.product__navLink_active]: index === activeItem,
        })}
        disabled={!isDisable}
      >
        <div className={classes.product__navNumber}>{index + 1} STEP</div>
        <div className={classes.product__navInfo}>
          <div className={classes.product__navInfoCaption}>
            {index === 0
              ? `CHOOSE YOUR ${item.name}`
              : `ADD ${item.name} (${item.count})`}
          </div>
        </div>
      </button>
    </li>
  );
};

ProductsTab.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    count: PropTypes.number,
  }),
  index: PropTypes.number.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  activeItem: PropTypes.number.isRequired,
  // TODO fixed it
  // eslint-disable-next-line react/forbid-prop-types
  isDisable: PropTypes.any,
};

ProductsTab.defaultProps = {
  isDisable: false,
};

export default ProductsTab;
