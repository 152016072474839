export const SHOW_ANIMATION = 'ANIMATE/SHOW_ANIMATION'
export const HIDE_ANIMATION = 'ANIMATE/HIDE_ANIMATION'
export const SKIP_ANIMATIONS = 'ANIMATE/SKIP_ANIMATIONS'

export function hideAnimation() {
  return {
    type: HIDE_ANIMATION,
  }
}

export function showAnimation() {
  return {
    type: SHOW_ANIMATION,
  }
}

export function skipAnimations(types) {
  return {
    type: SKIP_ANIMATIONS,
    payload: { types },
  }
}
