import classNames from "classnames";
import { useHistory } from "react-router-dom";
import React from "react";

import { Categories } from "./constants";
import classes from "./selectCategory.module.scss";
import Checked from "../../assets/images/icon/checked.svg";

const SelectCategory = () => {
  const history = useHistory();

  const handleSelectCategory = (category) => history.push(category.route);

  return (
    <div className={classes.container} data-testid="select-category">
      <div className={classes.title}>Build your own</div>
      <span className={classes.description}>Select a Category</span>
      <div className={classes.productList} data-testid="categories">
        {Categories.map((category) => (
          <div
            className={classNames(classes.category)}
            onClick={() => handleSelectCategory(category)}
            key={category.name}
          >
            <div className={classes.check}>
              <img src={Checked} alt="Selected" />
            </div>
            <div className={classes.imageContainer}>
              <img
                src={category.image}
                className={classes.image}
                alt={category.name}
              />
            </div>
            {category.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCategory;
