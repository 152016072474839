export const ADD_PRODUCTS = 'productsReducer/ADD_PRODUCTS'
export const SET_PRIMARY_CHOICE = 'productsReducer/SET_PRIMARY_CHOICE'
export const SET_CHOOSING_STEP = 'productsReducer/SET_CHOOSING_STEP'
export const SET_POUCHES = 'productsReducer/SET_POUCHES'
export const SET_PRIMARY_IMAGE = 'productsReducer/SET_PRIMARY_IMAGE'
export const OPEN_POPUP = 'productReducer/OPEN_ADDITIONAL_INFORMATION_PRODUCT'
export const CLOSE_POPUP = 'productReducer/CLOSE_ADDITIONAL_INFORMATION_PRODUCT'
export const CHANGE_ADDITIONAL_INFORMATION_INDEX = 'productReducer/CHANGE_ADDITIONAL_INFORMATION_INDEX'
export const SET_PRODUCT_MARGINS = 'productReducer/SET_PRODUCT_MARGINS'
export const SET_PRODUCT_HANDLE = 'productReducer/SET_PRODUCT_HANDLE'
export const SET_DRAGGED_PRODUCTS = 'productReducer/SET_DRAGGED_PRODUCTS'
export const SET_PRIMARY_PRODUCT = 'productReducer/SET_PRIMARY_PRODUCT'
export const SET_BASKET_BAR_PRODUCT = 'productReducer/SET_BASKET_BAR_PRODUCT'
export const SET_ACCESSORIES = 'productReducer/SET_ACCESSORIES'
export const SET_SHARED_DATA = 'productReducer/SET_SHARED_DATA'
export const SET_FIXED_PRODUCT = 'productReducer/SET_FIXED_PRODUCT'
export const SET_SNAPSHELLS = 'productReducer/SET_SNAPSHELLS'
export const SET_ACTIVE_ITEM = 'productReducer/SET_ACTIVE_ITEM'
export const NEW_BASKET_ITEM = 'productReducer/NEW_BASKET_ITEM'
export const SET_BASKET_ITEM_STATE = 'productReducer/SET_BASKET_ITEM_STATE'
export const RESET_BASKET = 'productReducer/RESET_BASKET'
export const RESET_CURRENT_COMBINATION = 'productReducer/RESET_CURRENT_COMBINATION'
export const REMOVE_BASKET_ITEM = 'productReducer/REMOVE_BASKET_ITEM'
export const SET_BASKET_ITEM_COUNT = 'productReducer/SET_BASKET_ITEM_COUNT'
export const SET_BASKET_ITEM_IMAGE = 'productReducer/SET_BASKET_ITEM_IMAGE'
export const SET_RECTS = 'productReducer/SET_RECTS'
export const SET_KNEEPADS_INFO = 'productReducer/SET_KNEEPADS_INFO'
export const SET_SHAPE_COUNT = 'productReducer/SET_SHAPE_COUNT'
export const SET_RECT = 'productReducer/SET_REACT'

export const setProducts = (products) => ({ type: ADD_PRODUCTS, payload: products })
export const setPrimaryChoice = (number) => ({ type: SET_PRIMARY_CHOICE, payload: number })
export const setChoosingStep = (number) => ({ type: SET_CHOOSING_STEP, payload: number })
export const setPouches = (array) => ({ type: SET_POUCHES, payload: array })
export const setAccessories = (array) => ({ type: SET_ACCESSORIES, payload: array })
export const setPrimaryImage = (path) => ({ type: SET_PRIMARY_IMAGE, payload: path })
export const openPopup = () => ({ type: OPEN_POPUP })
export const closePopup = () => ({ type: CLOSE_POPUP })
export const changeAdditionalInformationIndex = (index) => ({
  type: CHANGE_ADDITIONAL_INFORMATION_INDEX,
  payload: index,
})
export const setProductMargins = (margins) => ({ type: SET_PRODUCT_MARGINS, payload: margins })
export const setProductHandle = (handle) => ({ type: SET_PRODUCT_HANDLE, payload: handle })
export const setDraggedProducts = (map, index) => ({ type: SET_DRAGGED_PRODUCTS, payload: { map, index } })
export const setRects = (primary, fixed) => ({ type: SET_RECTS, payload: {primary, fixed} })
export const setPrimaryProduct = (product, productIndex, index) => ({
  type: SET_PRIMARY_PRODUCT,
  payload: { product, productIndex, index },
})
export const setBasketBarProducts = (products, index) => ({
  type: SET_BASKET_BAR_PRODUCT,
  payload: { products, index },
})
export const setSharedData = (data) => ({ type: SET_SHARED_DATA, payload: data })
export const setFixedProduct = (product, index) => ({ type: SET_FIXED_PRODUCT, payload: { product, index } })
export const setSelectedSnapshells = (products, index) => ({ type: SET_SNAPSHELLS, payload: { products, index } })
export const setBasketActiveIndex = (index) => ({ type: SET_ACTIVE_ITEM, payload: index })
export const addNewBasketItem = (pathname) => ({ type: NEW_BASKET_ITEM, payload: { pathname } })
export const setBasketItemState = (index, value) => ({ type: SET_BASKET_ITEM_STATE, payload: { index, value } })
export const removeBasketItem = (index) => ({ type: REMOVE_BASKET_ITEM, payload: { index } })
export const resetBasket = () => ({ type: RESET_BASKET })
export const resetCurrentCombination = (pathname) => ({ type: RESET_CURRENT_COMBINATION, payload: pathname })
export const setBasketItemCount = (index, count) => ({ type: SET_BASKET_ITEM_COUNT, payload: { index, count } })
export const setBasketItemImage = (index, image) => ({ type: SET_BASKET_ITEM_IMAGE, payload: { index, image } })
export const setKneePadsInfo = (index, info) => ({ type: SET_KNEEPADS_INFO, payload: { index, info } })
export const setShapeCount = (index, shapeCount) => ({ type: SET_SHAPE_COUNT, payload: { index, shapeCount } })
export const setRect = (index, rect) => ({ type: SET_RECT, payload: { index, rect } })
