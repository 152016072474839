import Belt from "../../assets/images/categories/belt.svg";
import Bag from "../../assets/images/categories/bag.svg";
import KneePad from "../../assets/images/categories/kneepad.svg";

export const Categories = [
  {
    name: "Belt",
    image: Belt,
    route: "/Belts",
  },
  {
    name: "Bag",
    image: Bag,
    route: "/Bags",
  },
  {
    name: "Knee Pad",
    image: KneePad,
    route: "/KneePads",
  }
];
