import React from 'react'
import PropTypes from 'prop-types'
import classes from './confirmDeleteBundle.module.scss'

const ConfirmDeleteBundle = ({ isVisible, closeModal, onConfirm }) => {
  return isVisible ? (
    <div className={classes.wrapper}>
      <div className={classes.popup}>
        <button
          type='button'
          className={classes.closeButton}
          aria-label='close basket'
          onClick={() => closeModal()}
        />
        <div className={classes.popupTitle}>
          If you remove the belt, all products will disappear. Do you want to remove the belt?
        </div>
        <div className={classes.popupButtonsWrapper}>
          <button type='button' className={classes.accept} onClick={() => closeModal()}>
            NO
          </button>
          <button
            type='button'
            className={classes.confirm}
            onClick={() => {
              onConfirm()
              closeModal()
            }}
          >
            YES
          </button>
        </div>
      </div>
    </div>
  ) : null
}

ConfirmDeleteBundle.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

ConfirmDeleteBundle.defaultProps = {}

export { ConfirmDeleteBundle }
