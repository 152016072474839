import {
  ADD_PRODUCTS,
  CHANGE_ADDITIONAL_INFORMATION_INDEX,
  CLOSE_POPUP,
  OPEN_POPUP,
  SET_ACCESSORIES,
  SET_BASKET_BAR_PRODUCT,
  SET_CHOOSING_STEP,
  SET_DRAGGED_PRODUCTS,
  SET_FIXED_PRODUCT,
  SET_POUCHES,
  SET_PRIMARY_CHOICE,
  SET_PRIMARY_IMAGE,
  SET_PRIMARY_PRODUCT,
  SET_PRODUCT_HANDLE,
  SET_PRODUCT_MARGINS,
  SET_SHARED_DATA,
  SET_SNAPSHELLS,
  SET_ACTIVE_ITEM,
  NEW_BASKET_ITEM,
  SET_BASKET_ITEM_STATE,
  RESET_BASKET,
  REMOVE_BASKET_ITEM,
  RESET_CURRENT_COMBINATION,
  SET_BASKET_ITEM_COUNT,
  SET_BASKET_ITEM_IMAGE,
  SET_RECTS,
  SET_KNEEPADS_INFO,
  SET_SHAPE_COUNT,
  SET_RECT,
  setProducts,
} from '../actions/productsActions'
import { getProducts } from '../../api/api'

const combinationInitialState = { // combination initial state
  pathname: '/Belts',
  draggedProducts: {},
  kneePadsInfo: null,
  rects: null,
  primaryImage: null,
  fixedImage: null,
  fixedProduct: null,
  primaryProduct: null,
  basketBar: [],
  selectedSnapshells: [],
  primaryChoice: null,
  image: null,
  updateImage: false,
  rect: null,
  count: 1,
  shapeCount: null,
  saved: false,
}

export const initialState = {
  products: [],
  isLoaded: false,
  activeStep: 0,
  pouches: null,
  accessories: [],
  isPopupState: false,
  additionalInformationIndex: null,
  margins: null,
  handle: null,
  basketList: [{ ...combinationInitialState }],
  activeIndex: 0,
}

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_KNEEPADS_INFO: {
      const { index, info } = action.payload
      const newBasketList = [...state.basketList]
      newBasketList[index].kneePadsInfo = info
      newBasketList[index].updateImage = true
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_RECTS: {
      const newBasketList = [...state.basketList]
      newBasketList[state.activeIndex].rects = action.payload
      newBasketList[state.activeIndex].updateImage = true

      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_BASKET_ITEM_COUNT: {
      const { index, count } = action.payload
      const newBasketList = [...state.basketList]
      newBasketList[index].count = Math.max(count, 1)

      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case RESET_CURRENT_COMBINATION: {
      const pathname = action.payload
      const newBasketList = [...state.basketList]
      newBasketList[0] = { ...combinationInitialState, pathname }
      return {
        ...state,
        activeIndex: 0,
        basketList: newBasketList,
      }
    }

    case REMOVE_BASKET_ITEM: {
      const { activeIndex } = state
      const removeIndex = action.payload.index

      const basketList = []
      if (removeIndex === 0) {
        const data = [...state.basketList]
        data.splice(0, 1, { ...combinationInitialState })
        basketList.push(...data)
      } else {
        basketList.push(...state.basketList.filter((e, i) => i !== removeIndex))
      }

      const newActiveIndex = removeIndex === 0 ?
        activeIndex :
        ((removeIndex < activeIndex) && (activeIndex - 1)) + ((removeIndex === activeIndex) && 0) + ((removeIndex > activeIndex) && activeIndex)
      return {
        ...state,
        basketList,
        activeIndex: newActiveIndex,
      }
    }

    case RESET_BASKET: {
      return {
        ...state,
        activeIndex: 0,
        basketList: [{ ...combinationInitialState }],
      }
    }

    case NEW_BASKET_ITEM: {
      const { pathname } = action.payload
      const { activeIndex } = state
      const newBasketList = [...state.basketList]
      if (activeIndex === 0) {
        if (newBasketList[activeIndex].primaryProduct) {
          newBasketList[activeIndex].saved = true
          newBasketList.splice(0, 0, { ...combinationInitialState, pathname })
          return {
            ...state,
            activeIndex: 0,
            basketList: newBasketList,
          }
        }
      }
      return {
        ...state,
        activeIndex: 0,
      }
    }

    case SET_BASKET_ITEM_IMAGE: {
      const { index, image } = action.payload
      const newBasketList = [...state.basketList]
      newBasketList[index].image = image
      newBasketList[index].updateImage = false
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_BASKET_ITEM_STATE: {
      const { index, value } = action.payload
      const newBasketList = [...state.basketList]
      newBasketList[index].opened = value
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_ACTIVE_ITEM: {
      return {
        ...state,
        activeIndex: action.payload,
      }
    }

    case ADD_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      }
    }

    case SET_CHOOSING_STEP: {
      return {
        ...state,
        activeStep: action.payload,
      }
    }

    case SET_PRIMARY_CHOICE: {
      const newBasketList = [...state.basketList]
      newBasketList.splice(state.activeIndex, 1, {
        ...newBasketList[state.activeIndex],
        primaryChoice: action.payload,
      })
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_POUCHES: {
      return {
        ...state,
        pouches: action.payload,
      }
    }

    case SET_ACCESSORIES: {
      return {
        ...state,
        accessories: action.payload,
      }
    }

    case SET_PRIMARY_IMAGE: {
      const newBasketList = [...state.basketList]
      newBasketList.splice(state.activeIndex, 1, {
        ...newBasketList[state.activeIndex],
        primaryImage: action.payload,
      })
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case OPEN_POPUP: {
      return {
        ...state,
        isPopupState: true,
      }
    }

    case CLOSE_POPUP: {
      return {
        ...state,
        isPopupState: false,
      }
    }

    case CHANGE_ADDITIONAL_INFORMATION_INDEX: {
      return {
        ...state,
        additionalInformationIndex: action.payload,
      }
    }

    case SET_PRODUCT_MARGINS: {
      return {
        ...state,
        margins: action.payload,
      }
    }

    case SET_PRODUCT_HANDLE: {
      return {
        ...state,
        handle: action.payload,
      }
    }

    case SET_DRAGGED_PRODUCTS: {
      const { index = -1, map } = action.payload
      const newBasketList = [...state.basketList]
      const i = index > -1 ? index : state.activeIndex
      newBasketList.splice(i, 1, {
        ...newBasketList[i],
        draggedProducts: map,
        updateImage: true,
      })
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_SHAPE_COUNT: {
      const { index = -1, shapeCount } = action.payload
      const newBasketList = [...state.basketList]
      const i = index > -1 ? index : state.activeIndex
      newBasketList.splice(i, 1, {
        ...newBasketList[i],
        shapeCount,
        updateImage: true,
      })
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_RECT: {
      const { index = -1, rect } = action.payload
      const newBasketList = [...state.basketList]
      const i = index > -1 ? index : state.activeIndex
      newBasketList.splice(i, 1, {
        ...newBasketList[i],
        rect,
        updateImage: true,
      })
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_PRIMARY_PRODUCT: {
      const { index = -1, productIndex, product } = action.payload
      const newBasketList = [...state.basketList]

      const i = index > -1 ? index : state.activeIndex
      newBasketList.splice(i, 1, {
        ...newBasketList[i],
        primaryProduct: product,
        primaryProductIndex: productIndex,
      })
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_BASKET_BAR_PRODUCT: {
      const { index = -1, products } = action.payload
      const newBasketList = [...state.basketList]

      const i = index > -1 ? index : state.activeIndex
      newBasketList.splice(i, 1, {
        ...newBasketList[i],
        basketBar: products,
        updateImage: true,
      })
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_FIXED_PRODUCT: {
      const { index = -1, product } = action.payload
      const newBasketList = [...state.basketList]

      const i = index > -1 ? index : state.activeIndex
      newBasketList.splice(i, 1, {
        ...newBasketList[i],
        fixedProduct: product,
        fixedImage: product?.image.fixed,
        updateImage: true,
      })
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    case SET_SHARED_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case SET_SNAPSHELLS: {
      const { index = -1, products } = action.payload
      const newBasketList = [...state.basketList]

      const i = index > -1 ? index : state.activeIndex
      newBasketList.splice(i, 1, {
        ...newBasketList[i],
        selectedSnapshells: products,
        updateImage: true,
      })
      return {
        ...state,
        basketList: newBasketList,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}

export const requestProducts = () => {
  return async (dispatch) => {
    const productsList = await getProducts()
    dispatch(setProducts(productsList.data.data))
  }
}
