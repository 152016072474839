import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import classes from './tooltip.module.scss'

const classMap = {
  right: classes.triangleRight,
  top: classes.triangleTop,
  bottom: classes.triangleBottom,
  topRight: classes.triangleRightCorner
}

const Tooltip = ({ corner, position, title, content }) => {
  return (
    <div className={classes.container} style={position}>
      <div className={classes.caption}>{title}</div>
      <div className={classes.desc}>{content}</div>
      {/* TODO: triangle change position with classes, triangleTop, triangleRight, triangleRightCorner, triangleBottom, triangleLeft */}
      <div className={classNames(classes.triangle, classMap[corner])} />
    </div>
  )
}

Tooltip.propTypes = {
  corner: PropTypes.string.isRequired,
  position: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  content: PropTypes.string,
}

Tooltip.defaultProps = {
  position: {},
  title: '',
  content: ''
}

export default Tooltip
