import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  addNewBasketItem,
  removeBasketItem,
  setBasketActiveIndex,
  setBasketBarProducts,
  setBasketItemCount,
  setBasketItemImage,
  setBasketItemState,
  setChoosingStep,
  setDraggedProducts,
  setFixedProduct,
  setPouches,
  setPrimaryProduct,
  setSelectedSnapshells,
} from "@redux/actions/productsActions";
import { Loader } from "@components/loader/loader";
import arrowBottomIcon from "@assets/images/icon/arrow-bottom.svg";
import { showAnimation } from "@redux/actions/animateActions";
import { ConfirmDeleteBundle } from "@components/popups/confirmDeleteBundle";
import CloseIcon from "@assets/images/icon/close.svg";
import TrashIcon from "@assets/images/icon/trash-gray.svg";
import { freeSpaceIndex } from "@components/productsMenu/helper";

import classes from "./productsBasket.module.scss";

const SET_BASKET_DATA = 'SET_BASKET_DATA'
const SET_IMAGE = 'SET_IMAGE'

const initialState = {
  basketData: [],
}

const stateReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_BASKET_DATA:
      return { ...state, basketData: payload }
    case SET_IMAGE: {
      const { index, image } = payload
      const newBasketData = [...state.basketData]
      newBasketData.splice(index, 1, { ...newBasketData[index], image })
      return { ...state, basketData: newBasketData }
    }
    default:
      return state
  }
}

const ProductsBasket = ({ buyNowHandler, drawCanvas, loadingBuyNow }) => {
  const location = useLocation()
  const history = useHistory()

  const [toggleBasket, setToggleBasket] = React.useState(false)
  const [totalPrice, setTotalPrice] = React.useState(0)
  const [confirmModal, setConfirmModal] = React.useState({ isVisible: false, index: -1 })

  const dispatch = useDispatch()

  const { basketList, activeIndex } = useSelector(({ products }) => products)

  const [{ basketData }, dispatchState] = React.useReducer(stateReducer, initialState)

  const deleteProductHandler = (index) => {
    dispatch(removeBasketItem(index))

    if (activeIndex === index) {
      dispatch(showAnimation())
      dispatch(setChoosingStep(0))
      dispatch(setPouches(null))
    }
  }

  const plusProductHandler = (index) => {
    dispatch(setBasketItemCount(index, basketList[index].count + 1))
  }

  const minusProductHandler = (index) => {
    dispatch(setBasketItemCount(index, basketList[index].count - 1))
  }

  const minusProductItemHandler = (product, index) => {
    const item = basketList[index]
    const basketBarItemIndex = item.basketBar.findIndex(e => e.id === product.id)
    if (basketBarItemIndex > -1) {
      if (item.basketBar[basketBarItemIndex].count === 1) {
        const basketBar = item.basketBar.filter((e, i) => i !== basketBarItemIndex)
        dispatch(setBasketBarProducts(basketBar, index))
      } else {
        const basketBar = item.basketBar.map((e, i) => i !== basketBarItemIndex ? e : { ...e, count: e.count - 1 })
        dispatch(setBasketBarProducts(basketBar, index))
      }
      return
    }

    const draggedProductsKey = Object.entries(item.draggedProducts).find(([, v]) => v.item.id === product.id)?.[0]
    if (draggedProductsKey) {
      const newDraggedProducts = { ...item.draggedProducts }
      delete newDraggedProducts[draggedProductsKey]
      dispatch(setDraggedProducts(newDraggedProducts, index))
      return
    }

    const selectedSnapshellsIndex = item.selectedSnapshells.findIndex(e => e.id === product.id)
    if (selectedSnapshellsIndex > -1) {
      const selectedSnapshellsProduct = item.selectedSnapshells[selectedSnapshellsIndex]
      if (selectedSnapshellsProduct.count === 1) {
        const selectedSnapshells = item.selectedSnapshells.filter((e, i) => i !== selectedSnapshellsIndex)
        dispatch(setSelectedSnapshells(selectedSnapshells, index))
      } else {
        const selectedSnapshells = item.selectedSnapshells.map((e, i) => i !== selectedSnapshellsIndex ? e : {
                ...e,
                count: selectedSnapshellsProduct.count - 1,
        })
        dispatch(setSelectedSnapshells(selectedSnapshells, index))
              }
      return
    }

    if (item.primaryProduct === product) {
      dispatch(setPrimaryProduct({
            ...item.primaryProduct,
            count: Math.max(item.primaryProduct.count - 1, 1),
      }, item.primaryProductIndex, index))
      return
    }

    if (item.fixedProduct === product) {
      if (item.fixedProduct.count === 1) {
        dispatch(setFixedProduct(null, index))
      } else {
        dispatch(setFixedProduct({
              ...item.fixedProduct,
              count: item.fixedProduct.count - 1,
        }, index))
      }
    }
  }

  const plusProductItemHandler = (product, index) => {
    const {
      basketBar,
      draggedProducts,
      selectedSnapshells,
      primaryProduct,
      primaryProductIndex,
      fixedProduct,
      shapeCount,
    } = basketList[index]

    if (product.position.includes('movable')) {
      const index1 = freeSpaceIndex(
        draggedProducts,
        shapeCount,
        product.shapeSize,
      )
      if (index1 !== -1) {
        dispatch(
          setDraggedProducts({
              ...draggedProducts,
              [index1]: {
                item: product,
                size: product.shapeSize,
                url: product.image.movable,
              },
          }, index),
          )
        return
      }
    }

    const basketBarItemIndex = basketBar.findIndex(e => e.id === product.id)
    if (basketBarItemIndex > -1) {
      dispatch(setBasketBarProducts(basketBar.map((e, i) => i !== basketBarItemIndex ? e : {
                  ...e,
                  count: e.count + 1,
      }), index))
      return
    }

    const draggedProductsKey = Object.entries(draggedProducts).find(([, v]) => v.item.id === product.id)?.[0]
    if (draggedProductsKey) {
      dispatch(setBasketBarProducts([...basketBar, {
              count: 1,
              id: draggedProducts[draggedProductsKey].item.id,
              product: draggedProducts[draggedProductsKey].item,
      }], index))
      return
    }

    const selectedSnapshellsIndex = selectedSnapshells.findIndex(e => e.id === product.id)
    if (selectedSnapshellsIndex > -1) {
      dispatch(setSelectedSnapshells(selectedSnapshells.map((e, i) => i !== selectedSnapshellsIndex ? e : {
                  ...e,
                  count: Math.max(e.count + 1, 2),
      }), index))
      return
    }

    if (primaryProduct === product) {
      dispatch(setPrimaryProduct({ ...primaryProduct, count: primaryProduct.count + 1 }, primaryProductIndex, index))
      return
    }

    if (fixedProduct === product) {
      dispatch(setFixedProduct({
            ...fixedProduct,
            count: fixedProduct.count + 1,
      }, index))
    }
  }

  React.useEffect(() => {
    basketList.forEach((item, index) => {
      if (item.updateImage) {
        (() => {
          const i = index
          drawCanvas(item, i).then(image => {
              if (image) {
              dispatch(setBasketItemImage(i, image))
              }
          }).catch(() => {
            })
        })()
      }
    })
  }, [basketList])

  React.useEffect(() => {
    const data = []
    setTotalPrice(0)
    const bundleCount = 1
    basketList.forEach((e, index) => {
      const {
        draggedProducts, primaryProduct, fixedProduct, selectedSnapshells, basketBar, opened, pathname, image, count,
      } = e
      const items = []
      let total = 0

      if (primaryProduct) {
        items.push({
          count: primaryProduct.count,
          product: primaryProduct,
        })
        total += primaryProduct.price * primaryProduct.count
      }

      if (fixedProduct) {
        items.push({
          count: fixedProduct.count,
          product: fixedProduct,
        })
        total += fixedProduct.price * fixedProduct.count
      }

      Object.keys(draggedProducts).forEach((index) => {
        if (draggedProducts[index]) {
          total += draggedProducts[index].item.price
          const item = items.find(
            ({ product }) => product.id === draggedProducts[index].item.id,
          )

          if (item) {
            item.count += 1
          } else {
            items.push({
              count: 1,
              product: draggedProducts[index].item,
            })
          }
        }
      })

      basketBar.forEach(({ id, product, count }) => {
        total += product.price * count
        const item = items.find(({ product }) => product.id === id)

        if (item) {
          item.count += count
        } else {
          items.push({
            count,
            product,
          })
        }
      })

      selectedSnapshells.forEach((shell) => {
        total += shell.price * (shell.count || 1)
        const item = items.find(({ product }) => product.id === shell.id)

        if (item) {
          item.count += shell.count || 1
        } else {
          items.push({
            count: shell.count,
            product: shell,
          })
        }
      })
      setTotalPrice(p => p + total * count)

      const title = `BUNDLE ${bundleCount.toString().padStart(2, '0')} - ${pathname.substring(1)}`

      data.push({
        data: items,
        cartData: {
          totalPrice: total,
          title,
          image,
          count,
          active: opened,
          pathname,
          index,
        },
        index,
      })
    })
    dispatchState({ type: SET_BASKET_DATA, payload: data })
  }, [basketList])

  const cartItemHeader = React.useCallback(
    (cartData) => (
      <div
        className={classNames(
          classes.product__quantityItem,
          classes.product__quantityItem__header
        )}
      >
        <button
          className={classNames(
            classes.product__quantityDropdown,
            cartData.active && classes.product__quantityDropdownActive
          )}
          type="button"
          onClick={() => {
          dispatch(setBasketItemState(cartData.index, !cartData.active))
          }}
        >
          <span className="img-container">
            <img src={arrowBottomIcon} alt="dropdown" />
          </span>
        </button>
        <div
          className={classes.product__quantity__card__data__image__container}
        >
          <img src={cartData.image} alt="some" />
        </div>

        <div
          className={classNames(
            classes.product__quantityInfo,
            classes.product__quantityInfo__header
          )}
        >
          <div className={classes.product__quantityInfo__row}>
            <div
              className={classNames(
                classes.product__quantityInfo__code,
                classes.product__quantityInfo__code__principal
              )}
            >
              {cartData.title}
            </div>
            <div className={classes.btn__navContainer}>
              <button
                className={classes.btn__navEdit}
                onClick={() => {
                  if (location.pathname !== cartData.pathname) {
                history.push(cartData.pathname)
                  }
              dispatch(setBasketActiveIndex(cartData.index))
                }}
                type='button'
              >
                EDIT
              </button>
            </div>
          </div>

          <div className={classes.product__quantityInfo__row}>
            <div
              className={classNames(
                classes.product__quantityInfo__price,
                classes.product__quantityInfo__price__principal
              )}
            >
              ${cartData.totalPrice.toFixed(2)}
            </div>
            <div className={classes.product__quantityInfo__edit__remove}>
              <div className={classes.product__quantityInfo__edit__count}>
                <button
                  type="button"
                  className={classNames(
                    classes.product__quantityConfigChange__btn,
                    classes.product__quantityConfigChange__btnPrev
                  )}
                  onClick={() => minusProductHandler(cartData.index)}
                  aria-label="minus quantity"
                />
                <div className={classes.product__quantityConfigChange__value}>
                  {cartData.count}
                </div>
                <button
                  type="button"
                  className={classNames(
                    classes.product__quantityConfigChange__btn,
                    classes.product__quantityConfigChange__btnNext
                  )}
                  onClick={() => plusProductHandler(cartData.index)}
                  aria-label="plus quantity"
                />
              </div>
              <img src={TrashIcon}  onClick={() =>
                  setConfirmModal({ isVisible: true, index: cartData.index })
                } 
                className={classes.product__quantityConfig__btn__header}
              />
            </div>
          </div>
        </div>
      </div>
    ),
    [location.pathname, activeIndex]
  );

  const LoaderOrBuy = () => {
    if (loadingBuyNow) {
      return <Loader type="button" testId="buy-now-loader" />
    }
    return "BUY NOW"
  }

  return (
    <>
      <div
        data-testid="products-basket"
        className={classNames(
          classes.product__tabBuy,
          classes.product__tabBuy_active,
        )}
      >
        <div className={classes.product__tapBuyPrice}>
          <button
            type='button'
            className={classNames(classes.product__tabBuyPriceBtn, {
              [classes.product__tabBuyPriceBtn_down]: toggleBasket,
            })}
            aria-label='close basket'
            onClick={() => setToggleBasket((state) => !state)}
            data-testid="button-toggle-basket"
            disabled={loadingBuyNow}
          />
          <div className={classes.product__tabBuyPriceNumber}>
            <span>$</span>
            <span>{(totalPrice).toFixed(2)}</span>
          </div>
        </div>
        <a
          onClick={(evt) => {
            evt.preventDefault()
            if (!toggleBasket) {
              dispatch(addNewBasketItem(location.pathname))
              dispatch(setChoosingStep(0))
            } else {
              buyNowHandler()
            }
          }}
          className={classes.product__tabBuyBtn}
        >
          {toggleBasket ?  <LoaderOrBuy /> : 'Add bundle to Cart'}
        </a>
      </div>
      <div
        className={classNames(classes.product__quantity, {
          [classes.product__quantity_active]: toggleBasket,
        })}
      >
        <div className={classes.product__quantityHeader}>
          <div className={classes.product__quantityHeaderCaption}>
            Your bundle Products
          </div>
          <img
            src={CloseIcon}
            className={classes.product__quantityHeaderBtn}
            onClick={() => setToggleBasket(false)}
            alt="Close"
          />
        </div>
        <ul className={classes.product__quantityList}>
          {basketData.map(({ data, cartData, index }) => data.length ? (
              <li key={index.toString()}>
                {!!cartData && cartItemHeader(cartData)}
                <ul className={classes.product__quantityListInner}>
                {cartData.active && data.map(({ product, count }) => (
                  <li key={product.id} className={classes.product__quantityItem}>
                      <div className={classes.product__quantity__card__data__image__container__item}>
                        <img src={product.image.primary} alt='some' />
                      </div>
                    <div className={classes.product__quantityInfo}>
                          <div className={classes.product__quantityInfo__code}>
                            {product.additional.model}
                          </div>
                          <div className={classes.product__quantityInfo__name}>
                            {product.additional.name ||
                              product.additional.model}
                          </div>
                          <div className={classes.product__quantityInfo__price}>
                        $
                        {(product.price * count).toFixed(2)}
                          </div>
                    </div>
                        <div className={classes.product__quantityConfig}>
                        <div className={classes.product__quantityConfigChange}>
                            <button
                          type='button'
                              className={classNames(
                                classes.product__quantityConfigChange__btn,
                            classes.product__quantityConfigChange__btnPrev,
                              )}
                          onClick={() => minusProductItemHandler(product, index)}
                          aria-label='minus quantity'
                            />
                            <div
                          className={classes.product__quantityConfigChange__value}
                            >
                               {count}
                            </div>
                            <button
                          type='button'
                              className={classNames(
                                classes.product__quantityConfigChange__btn,
                            classes.product__quantityConfigChange__btnNext,
                              )}
                          onClick={() => plusProductItemHandler(product, index)}
                          aria-label='plus quantity'
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </li>
          ) : null)}
        </ul>
      </div>
      <ConfirmDeleteBundle
        closeModal={() => setConfirmModal({ isVisible: false, index: -1 })}
        isVisible={confirmModal.isVisible}
        onConfirm={() => deleteProductHandler(confirmModal.index)}
      />
    </>
  )
}

ProductsBasket.propTypes = {
  buyNowHandler: PropTypes.func.isRequired,
  drawCanvas: PropTypes.func.isRequired,
  loadingBuyNow: PropTypes.bool
}

ProductsBasket.defaultProps = {}

export default ProductsBasket
