import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MediaQuery, { useMediaQuery } from "react-responsive";

import {
  createCanvas,
  createCells,
  createKneePadsCanvas,
  droppedIndex,
  maxSpaceSize,
  pointIndex,
  possibleToDrop,
} from "./helper";
import {
  resetCurrentCombination,
  setAccessories,
  setBasketBarProducts,
  setChoosingStep,
  setDraggedProducts,
  setFixedProduct,
  setKneePadsInfo,
  setPouches,
  setPrimaryChoice,
  setPrimaryImage,
  setPrimaryProduct,
  setProductHandle,
  setProductMargins,
  setRect,
  setRects,
  setSelectedSnapshells,
  setShapeCount,
  setSharedData,
} from "@redux/actions/productsActions";
import EyeIcon from "@assets/images/icon/eye.svg";
import Product from "@components/product/product";
import Confirm from "@components/confirm/confirm";
import { Loader } from "@components/loader/loader";
import { useVisibility } from "@hooks/useVisibility";
import KneePads from "@components/kneePads/kneePads";
import RemoveIcon from "@assets/images/icon/remove.svg";
import ShareLink from "@components/shareLink/shareLink";
import BagHint from "@assets/images/productHint/Bag.png";
import { BAGS, BELTS, KNEEPAD } from "@constants/routes";
import BeltHint from "@assets/images/productHint/Belt.png";
import PadHint from "@assets/images/productHint/KneePads.png";
import BagStartAnimation from "@components/animation/bag/bag";
import PadStartAnimation from "@components/animation/pad/pad";
import ProductsTabs from "@components/productsTabs/productsTabs";
import BeltStartAnimation from "@components/animation/belt/belt";
import { requestProducts } from "@redux/reducers/productsReducer";
import { buyNow, getBundle, getState, shareState } from "@api/api";
import HeaderProductMenu from "@components/headerProductMenu/headerProductMenu";
import ProductsBasket from "@components/productsBasket/productsBasket";
import ProductBasketBar from "@components/productBasketBar/productBasketBar";
import FirstTooltipDesktop from "@components/tooltip/desktop/firstTooltipDesktop";
import BagDragAnimation from "@components/animation/bagDragAnimation/bagDragAnimation";
import PadDragAnimation from "@components/animation/padDragAnimation/padDragAnimation";
import BeltDragAnimation from "@components/animation/beltDragAnimation/beltDragAnimation";

import classes from "./productsMenu.module.scss";

const initialState = {
  isEditing: false,
  isDrag: false,
  isTouchStart: false,
  touchStartPoint: { x: 0, y: 0 },
  point: { x: 0, y: 0 },
  rect: { x: 0, y: 0, width: -1, height: -1 },
  dragItem: null,
  dragItemIndex: null,
  shapeCount: null,
  dropCells: [],
  startScroll: null,
  possibleToDrop: {},
  hoverIndex: -1,
  maxSpaceSize: 0,
  kneePads: {},
  resetPopup: false,
  productsCounts: [],
};

const stateReducer = (state, { payload }) => ({ ...state, ...payload });

const routesRequest = {
  [BELTS]: "belts",
  [BAGS]: "bags",
  [KNEEPAD]: "kneePads",
};

const animationComponents = {
  [`${BELTS}#0`]: <BeltStartAnimation />,
  [`${BELTS}#1`]: <BeltDragAnimation />,
  [`${BAGS}#0`]: <BagStartAnimation />,
  [`${BAGS}#1`]: <BagDragAnimation />,
  [`${KNEEPAD}#0`]: <PadStartAnimation />,
  [`${KNEEPAD}#1`]: <PadDragAnimation />,
};

const hintsImage = {
  [BELTS]: BeltHint,
  [BAGS]: BagHint,
  [KNEEPAD]: PadHint,
};

const tooltips = [
  <>
    <MediaQuery key="tooltip-0-desktop" minDeviceWidth={1025}>
      <FirstTooltipDesktop />
    </MediaQuery>
  </>,
];

const ProductsMenu = () => {
  const passedAnimations = useSelector(
    ({ animate }) => animate.passedAnimations
  );
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const {
    products,
    activeStep,
    pouches,
    accessories,
    isPopupState,
    margins,
    handle,
    additionalInformationIndex,
  } = useSelector(({ products }) => products);

  const {
    draggedProducts,
    basketBar,
    selectedSnapshells,
    primaryProduct,
    primaryProductIndex,
    primaryImage,
    primaryChoice,
    fixedImage,
    fixedProduct,
  } = useSelector(({ products }) => products.basketList[products.activeIndex]);

  const reduxProductsState = useSelector(({ products }) => products);

  const [state, dispatchState] = React.useReducer(stateReducer, initialState);

  const dragImg = React.useRef(null);
  const dropShapes = React.useRef(null);
  const tabList = React.useRef(null);
  const primaryImg = React.useRef(null);
  const fixedImg = React.useRef(null);
  const screenshot = React.useRef(null);
  const draggedProductImages = React.useRef([]);

  const [tooltip, setTooltip] = React.useState(-1);
  const [noTutorials, setNoTutorials] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState(null);
  const [bundleId, setBundleId] = React.useState(null);
  const [redirectUrl, setRedirectUrl] = React.useState(null);
  const [visibleHeader, setVisibleHeader] = React.useState(true);
  const [loadingShare, setLoadingShare] = React.useState(false);
  const [loadingBuyNow, setLoadingBuyNow] = React.useState(false);
  const [loadingProducts, setLoadingProducts] = React.useState(false);
  const [showHint, setShowHint] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState(0);
  const [isVisibleProduct, currentElementProduct] = useVisibility({
    initialState: true,
    shrink: !visibleHeader,
  });
  const [isVisiblePouches, currentElementPouches] = useVisibility({
    shrink: !visibleHeader,
  });
  const [isVisibleAccesories, currentElementAccesories] = useVisibility({
    shrink: !visibleHeader,
  });
  const isDesktop = useMediaQuery({ minWidth: 1281 });
  const isTabletOrDesktop = useMediaQuery({ minWidth: 981 });
  const [opacityAnimation, setOpacityAnimation] = React.useState(false);

  const queryParams = React.useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const draggedProductsNotEmpty = React.useMemo(() => {
    return location.pathname !== "/KneePads"
      ? Object.values(draggedProducts).some(Boolean)
      : selectedSnapshells.length > 0;
  }, [
    Object.values(draggedProducts).some(Boolean),
    location.pathname,
    selectedSnapshells,
  ]);

  const getItems = () => {
    const items =
      location.pathname === "/KneePads"
        ? [
            { name: "Knee Pads" },
            { name: "Snapshells", count: pouches ? pouches.length : 0 },
          ]
        : [
            { name: location.pathname.slice(1, location.pathname.length - 1) },
            {
              name: "Pouches",
              count: pouches ? pouches.filter(filterPouches).length : 0,
            },
            {
              name: "Accessories",
              count: accessories ? accessories.length : 0,
            },
          ];

    return items;
  };

  const setActiveStep = (index) => {
    dispatch(setChoosingStep(index));
    handleScrollTop();
  };

  const setPouchesHandler = (product, index) => {
    dispatch(
      setPouches(
        location.pathname === "/KneePads"
          ? product.snapshells || []
          : product.pouches
      )
    );
    dispatch(setAccessories(product.accessories || []));

    setActiveStep(1);
    dispatch(setPrimaryChoice(index));
    dispatch(setPrimaryImage(product.image.fixed));
    dispatch(setProductMargins(product.margin));
    dispatch(setProductHandle(product.handle));
  };

  const mouseMoveHandlers = (evt) => {
    dispatchState({
      payload: {
        point: { x: evt.clientX, y: evt.clientY },
      },
    });
  };

  const dragEndHandler = (evt) => {
    if (evt.cancelable) {
      evt.preventDefault();
    }
    if (!state.isDrag || !state.dragItem) {
      return;
    }

    if (state.possibleToDrop[state.hoverIndex]) {
      const index = droppedIndex(
        state.dropCells.map((cell) => !cell.free),
        state.hoverIndex,
        state.dragItem.shapeSize
      );

      dispatch(
        setDraggedProducts({
          ...draggedProducts,
          ...(state.dragItemIndex !== null
            ? { [state.dragItemIndex]: null }
            : {}),
          [index]: {
            item: state.dragItem,
            url: state.dragItem.image.movable,
            size: state.dragItem.shapeSize,
            rect: null,
          },
        })
      );
    }

    dispatchState({
      payload: {
        isDrag: false,
        dragItemIndex: null,
        startScroll: null,
        dragItem: null,
      },
    });

    window.removeEventListener("mouseup", dragEndHandler);
    document.removeEventListener("mousemove", mouseMoveHandlers);
  };

  const addToBasketBar = (product) => {
    const index = basketBar.findIndex(({ id }) => id === product.id);
    if (index !== -1) {
      dispatch(
        setBasketBarProducts(
          basketBar.map((item, i) => ({
            ...(i !== index ? item : { ...item, count: item.count + 1 }),
          }))
        )
      );
    } else {
      dispatch(
        setBasketBarProducts([
          ...basketBar,
          { id: product.id, count: 1, product },
        ])
      );
    }

    clearTimeout(timeoutId);
    setTimeoutId(setTimeout(setShowHint, 3000, false));
    setShowHint(true);
  };

  const resetHandler = React.useCallback(() => {
    setActiveStep(0);
    dispatch(setPrimaryImage(null));
    dispatch(setPrimaryChoice(null));
    dispatch(setFixedProduct(null));
    dispatch(setBasketBarProducts([]));
    dispatch(setPouches([]));
    dispatch(setAccessories([]));
    dispatch(setDraggedProducts({}));
    dispatch(setPrimaryProduct(null));
  }, [dispatch]);

  const openResetPopup = React.useCallback(() => {
    dispatchState({
      payload: {
        resetPopup: true,
      },
    });
  }, []);

  const resetPopupHandler = React.useCallback((result) => {
    if (result) {
      resetHandler();
    }
    dispatchState({
      payload: {
        resetPopup: false,
      },
    });
  }, []);

  const coordinateChangeHandler = React.useCallback((state) => {
    const payload = {};
    if (state.isDrag) {
      payload.hoverIndex = pointIndex(
        state.point,
        state.rect,
        state.shapeCount
      );
    }

    dispatchState({ payload });
  }, []);

  const resizeHandler = React.useCallback(() => {
    if (dropShapes.current) {
      dispatchState({
        payload: {
          rect: dropShapes.current.getBoundingClientRect(),
        },
      });
    }
  }, [dispatchState, dropShapes]);

  const drawCanvas = (
    {
      draggedProducts,
      rects,
      primaryImage,
      fixedImage,
      basketBar,
      kneePadsInfo,
      selectedSnapshells,
      shapeCount,
      pathname,
      rect,
    },
    index
  ) => {
    if (pathname === "/KneePads") {
      if (kneePadsInfo) {
        const {
          primaryRect,
          margins: { l, w, t, b },
        } = kneePadsInfo.info;
        const items = [
          { src: primaryImage, x: 0, y: 0, ...primaryRect },
          ...(selectedSnapshells?.map((product, index) => ({
            src: product.image.movable,
            x: primaryRect.width * (l + (index * w) / 2),
            y: primaryRect.height * t,
            width: primaryRect.width * w,
            height: primaryRect.height * (1 - t - b),
          })) || []),
        ];
        return createKneePadsCanvas(items, kneePadsInfo.size);
      }
      if (state.kneePads?.items && state.kneePads?.size) {
        return createKneePadsCanvas(state.kneePads.items, state.kneePads.size);
      }
      return new Promise((resolve) => {
        resolve(null);
      });
    }

    const primary = rects
      ? {
          src: primaryImage,
          rect: rects.primary,
        }
      : null;

    const cellWidth = (rect?.width || 0) / shapeCount;
    const dragged = rect
      ? Object.keys(draggedProducts)
          .filter((index) => draggedProducts[index])
          .map((index) => ({
            src: draggedProducts[index].url,
            rect: {
              x: rect.x + cellWidth * index,
              y: rect.y,
              width: cellWidth * draggedProducts[index].size,
              height: rect.height,
            },
          }))
      : [];

    const fixed =
      rects?.fixed && fixedImage
        ? {
            src: fixedImage,
            rect: rects.fixed,
          }
        : null;
    const basket = basketBar.map((e) => ({
      src: e.product.image.primary,
    }));
    if (primary) {
      return createCanvas(primary, dragged, fixed, basket, index);
    }
    return new Promise((resolve) => {
      resolve(null);
    });
  };

  const closeTooltip = React.useCallback(
    (index) => {
      if (index === tooltip) {
        localStorage.setItem(`tooltip-${tooltip}`, "true");
        setTooltip(-1);
      }
    },
    [tooltip]
  );

  const startReorderItems = (x, y) => {
    if (!dropShapes) {
      return;
    }
    let index = pointIndex(
      { x, y },
      dropShapes.current.getBoundingClientRect(),
      state.shapeCount
    );

    while (
      index !== -1 &&
      !state.dropCells[index].free &&
      !state.dropCells[index].item
    ) {
      index -= 1;
    }

    if (index === -1) {
      return;
    }

    closeTooltip(1);
    dispatchState({
      payload: {
        isDrag: true,
        dragItemIndex: index,
        startScroll: null,
        point: { x, y },
        dragItem: state.dropCells[index].item,
        rect: dropShapes.current.getBoundingClientRect(),
      },
    });
  };

  const mouseDownHandler = (evt) => {
    if (evt.target && evt.target.alt === "Remove") {
      return;
    }
    if (isDesktop) {
      const { clientX: x, clientY: y } = evt;

      startReorderItems(x, y);
    }
  };

  const pouchesMouseDownHandler = (pouch) => (evt) => {
    closeTooltip(0);
    if (!isDesktop) {
      return;
    }
    if (location.pathname === "/KneePads") {
      return;
    }
    if (state.maxSpaceSize >= pouch.shapeSize) {
      dispatchState({
        payload: {
          isDrag: true,
          point: {
            x: evt.clientX,
            y: evt.clientY,
          },
          dragItem: pouch,
          rect: dropShapes.current.getBoundingClientRect(),
        },
      });
    }
  };

  const handleAddToBundle = (pouch) => {
    if (location.pathname === "/KneePads") {
      return;
    }
    if (state.maxSpaceSize >= pouch.shapeSize) {
      let indexDrop = 0;
      for (const _drop of state.dropCells) {
        if (state.possibleToDrop[indexDrop]) {
          const index = droppedIndex(
            state.dropCells.map((cell) => !cell.free),
            indexDrop,
            pouch.shapeSize
          );

          dispatch(
            setDraggedProducts({
              ...draggedProducts,
              ...(state.dragItemIndex !== null
                ? { [state.dragItemIndex]: null }
                : {}),
              [index]: {
                item: pouch,
                url: pouch.image.movable,
                size: pouch.shapeSize,
                rect: null,
              },
            })
          );

          break;
        }
        ++indexDrop;
      }
    } else {
      addToBasketBar(pouch);
    }
  };

  const pouchesTouchStartHandler = (pouch) => (evt) => {
    closeTooltip(0);
    if (!isDesktop) {
      return;
    }
    if (location.pathname === "/KneePads") {
      return;
    }
    const {
      changedTouches: [{ clientX: x, clientY: y }],
    } = evt;
    if (state.maxSpaceSize >= pouch.shapeSize) {
      dispatchState({
        payload: {
          isDrag: false,
          isTouchStart: true,
          touchStartPoint: { x, y },
          point: { x, y },
          dragItem: pouch,
          rect: dropShapes.current.getBoundingClientRect(),
        },
      });
    }
  };

  const buyNowHandler = () => {
    setLoadingBuyNow(true);
    buyNow(
      {
        reduxState: reduxProductsState,
        state,
      },
      window.location.href,
      bundleId,
      redirectUrl
    )
      .then(({ url }) => {
        window.location.href = url;
      })
      .catch(() => setLoadingBuyNow(false));
  };

  const shareButtonHandler = () => {
    setLoadingShare(true);
    shareState(
      {
        reduxState: reduxProductsState,
        state,
      },
      window.location.href
    )
      .then(({ url }) => {
        setLoadingShare(false);
        setShareUrl(url);
      })
      .catch(() => setLoadingShare(false));
  };

  const toggleSnapshell = React.useCallback(
    (snapshell) => {
      if (selectedSnapshells.some((shell) => shell.id === snapshell.id)) {
        dispatch(
          setSelectedSnapshells(
            selectedSnapshells.filter((shell) => shell.id !== snapshell.id)
          )
        );
      } else {
        dispatch(
          setSelectedSnapshells([
            ...selectedSnapshells,
            { ...snapshell, count: 1 },
          ])
        );
      }
    },
    [selectedSnapshells]
  );

  const pouchesClickHandler = (pouch) => () => {
    closeTooltip(0);
    if (location.pathname === "/KneePads") {
      toggleSnapshell(pouch);
    } else {
      addToBasketBar(pouch);
    }
  };

  const setKneePadData = React.useCallback(
    (data) => {
      dispatch(setKneePadsInfo(reduxProductsState.activeIndex, data));
      dispatchState({
        payload: {
          kneePads: data,
        },
      });
    },
    [reduxProductsState.activeIndex]
  );

  const handleButtonEdit = () =>
    dispatchState({
      payload: {
        isEditing: !state.isEditing,
        isDrag: isDesktop ? !state.isDrag : state.isDrag,
      },
    });

  const handleRemove = (index) => {
    dispatch(
      setDraggedProducts({
        ...draggedProducts,
        [index]: undefined,
      })
    );
  };

  const handleRemoveFixed = () => {
    dispatch(setFixedProduct(null));
  };

  const handleRemovePoucheMobile = (index) => {
    if (!isDesktop && state.isEditing) {
      handleRemove(index);
    }
  };

  const handleRemoveFixedMobile = () => {
    if (!isDesktop && state.isEditing) {
      handleRemoveFixed();
    }
  };

  const handleScrollTop = () => {
    tabList.current.scrollIntoView({ behavior: "smooth", block: "start" });
    setVisibleHeader(true);
  };

  const handleClickBundle = (evt) => {
    if (state.isEditing) {
      const attributes = evt.target.attributes;
      let valid = false;
      for (const key in Object.keys(attributes)) {
        if (
          attributes[key].name === "src" ||
          attributes[key].name === "style"
        ) {
          valid = true;
          break;
        }
      }
      if (!valid) {
        handleButtonEdit();
      }
    }
  };

  React.useEffect(() => {
    if (
      primaryChoice === null &&
      !passedAnimations[`${location.pathname}#${activeStep}`]
    ) {
      setTooltip(-1);
      return;
    }

    if (activeStep !== 1) {
      setTooltip(-1);
      return;
    }

    if (!localStorage.getItem("tooltip-0")) {
      setTooltip(0);
      return;
    }

    if (draggedProductsNotEmpty && !localStorage.getItem("tooltip-1")) {
      setTooltip(1);
      return;
    }

    if (tooltip !== -1) {
      setTooltip(-1);
    }
  }, [
    tooltip,
    primaryChoice,
    activeStep,
    draggedProductsNotEmpty,
    passedAnimations[`${location.pathname}#${activeStep}`],
  ]);

  React.useEffect(() => {
    const array = [];
    Object.values(draggedProducts || {}).forEach((product) => {
      const index = array.findIndex((e) => e.id === product?.item.id);
      if (index > -1) {
        array[index].count = 1 + array[index].count;
      } else {
        array.push({ id: product?.item.id, count: 1 });
      }
    });
    basketBar.forEach((item) => {
      const index = array.findIndex((e) => e.id === item.id);
      if (index > -1) {
        array[index].count = item.count + array[index].count;
      } else {
        array.push({ id: item.id, count: item.count });
      }
    });
    if (fixedProduct?.id) {
      array.push({ id: fixedProduct.id, count: 1 });
    }
    dispatchState({
      payload: {
        productsCounts: array,
      },
    });
  }, [draggedProducts, basketBar, fixedProduct]);

  React.useEffect(() => {
    coordinateChangeHandler(state);
  }, [
    state.isDrag,
    state.point,
    state.rect,
    state.shapeCount,
    state.dragItemIndex,
  ]);

  React.useEffect(() => {
    setLoadingProducts(true);
    dispatch(requestProducts());
    dispatchState({
      payload: { rect: dropShapes.current?.getBoundingClientRect() },
    });
  }, []);

  React.useEffect(() => {
    if (!Array.isArray(products)) {
      setLoadingProducts(false);
    }
  }, [products]);

  React.useEffect(() => {
    if (state.isDrag) {
      window.addEventListener("mouseup", dragEndHandler);
      document.addEventListener("mousemove", mouseMoveHandlers);

      window.addEventListener("touchend", dragEndHandler);
    }

    return () => {
      window.removeEventListener("mouseup", dragEndHandler);
      document.removeEventListener("mousemove", mouseMoveHandlers);

      window.removeEventListener("touchend", dragEndHandler);
    };
  }, [state]);

  React.useEffect(() => {
    const dropCells = createCells(
      draggedProducts,
      state.shapeCount,
      state.dragItemIndex
    );
    const size = maxSpaceSize(dropCells.map((cell) => cell.free));

    dispatchState({
      payload: {
        dropCells,
        possibleToDrop: possibleToDrop(
          draggedProducts,
          state.shapeCount,
          state.dragItem,
          state.dragItemIndex
        ),
        maxSpaceSize: size,
      },
    });
  }, [draggedProducts, state.shapeCount, state.dragItem, state.dragItemIndex]);

  React.useEffect(() => {
    if (!hintsImage[location.pathname]) {
      history.push(`${BELTS}${location.search}`);
    }
    if (queryParams.get("tutorials") === "no") {
      setNoTutorials(true);
    }

    setBundleId(queryParams.get("bundleId"));
    setRedirectUrl(queryParams.get("redirectUrl"));

    if (queryParams.has("hash")) {
      getState(queryParams.get("hash")).then(
        ({ state: { state, reduxState } }) => {
          dispatch(setSharedData(reduxState));
          dispatchState({
            payload: {
              ...state,
              isDrag: false,
              isTouchStart: false,
              rect: dropShapes.current.getBoundingClientRect(),
            },
          });
        }
      );
    } else if (queryParams.has("bundleId")) {
      getBundle(queryParams.get("bundleId")).then(
        ({ state: { state, reduxState } }) => {
          dispatch(setSharedData(reduxState));
          dispatchState({
            payload: {
              ...state,
              isDrag: false,
              isTouchStart: false,
              rect: dropShapes.current.getBoundingClientRect(),
            },
          });
        }
      );
    } else {
      dispatch(resetCurrentCombination(location.pathname));
      dispatch(setChoosingStep(0));
    }
  }, [location.pathname, queryParams, dispatch]);

  React.useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler]);

  React.useEffect(() => {
    if (primaryProduct) {
      setPouchesHandler(primaryProduct, primaryProductIndex);
      dispatchState({
        payload: {
          shapeCount: primaryProduct.shape,
        },
      });
    }
  }, [primaryProduct, primaryProductIndex]);

  // React.useEffect(() => {
  //   const token = queryParams.get('tk')
  //   if (token) {
  //     verifyToken(token).then((result) => {
  //       if (!result) {
  //         const qp = new URLSearchParams(queryParams)
  //         qp.delete('tk')
  //         history.push({
  //           pathname: location.pathname,
  //           search: qp.toString(),
  //         })
  //         window.location.reload(false)
  //       }
  //     })
  //   }
  // }, [queryParams.get('tk')])

  const initDraggedRects = () => {
    let fixed = null;
    let primary = null;

    if (fixedProduct) {
      fixed = fixedImg?.current?.getBoundingClientRect();
    }
    if (primaryProduct) {
      primary = primaryImg?.current?.getBoundingClientRect();
    }
    dispatch(setRects(primary, fixed));
  };

  React.useEffect(() => {
    setTimeout(initDraggedRects, 300);
  }, [primaryImage, fixedImage]);

  React.useEffect(() => {
    dispatch(setShapeCount(reduxProductsState.activeIndex, state.shapeCount));
    dispatch(setRect(reduxProductsState.activeIndex, state.rect));
  }, [state.shapeCount, state.rect, reduxProductsState.activeIndex]);

  React.useEffect(() => {
    if (
      tabList.current.childElementCount &&
      tabList.current.childElementCount > 6
    ) {
      if (
        !isTabletOrDesktop &&
        (isVisibleAccesories || isVisiblePouches || isVisibleProduct)
      ) {
        setVisibleHeader(true);
      } else if (!isTabletOrDesktop) {
        setTimeout(() => setVisibleHeader(false), 200);
        if (opacityAnimation === false) {
          setOpacityAnimation(true);
          setTimeout(() => setOpacityAnimation(false), 300);
        }
      }
    }
  }, [isVisibleAccesories, isVisiblePouches, isVisibleProduct]);

  const selectPouchesProductInfo = (pouch) => {
    dispatchState({
      payload: {
        isDrag: false,
        point: {},
        dragItem: pouch,
        rect: dropShapes.current.getBoundingClientRect(),
      },
    });
  };

  const filterPouches = (pouche) =>
    (location.pathname === "/Bags" &&
      ((primaryProduct &&
        primaryProduct.additional.pinHeight &&
        primaryProduct.additional.pinHeight > pouche.additional.pinHeight) ||
        !primaryProduct ||
        !primaryProduct.additional.pinHeight)) ||
    location.pathname !== "/Bags";

  const renderPouches = () =>
    activeStep === 1 &&
    pouches &&
    pouches
      .filter(filterPouches)
      .map((pouch, index) => (
        <Product
          type="pouche"
          isDesktop={isDesktop}
          innerRef={index == 0 ? currentElementPouches : null}
          testId={`pouche-${index}`}
          key={pouch.id}
          count={
            state.productsCounts.find((e) => e.id === pouch.id)?.count || 0
          }
          primaryChoice={null}
          mouseDownHandler={pouchesMouseDownHandler(pouch)}
          touchStartHandler={pouchesTouchStartHandler(pouch)}
          clickHandler={pouchesClickHandler(pouch)}
          onAddToBundle={() => handleAddToBundle(pouch)}
          onShowProductInfo={() => selectPouchesProductInfo(pouch)}
          product={pouch}
          index={index}
          isPopupState={isPopupState}
          additionalInformationIndex={additionalInformationIndex}
          active={
            (state.isDrag &&
              state.dragItemIndex === null &&
              state.dragItem &&
              state.dragItem.id === pouch.id) ||
            (location.pathname === "/KneePads" &&
              selectedSnapshells.some((shell) => shell.id === pouch.id))
          }
        />
      ));

  const renderProducts = () =>
    loadingProducts ? (
      <Loader type="products" />
    ) : (
      activeStep === 0 &&
      products[routesRequest[location.pathname]] &&
      products[routesRequest[location.pathname]].map((product, index) => {
        const clickHandler = () => {
          dispatch(setPrimaryProduct({ ...product, count: 1 }, index));
          dispatch(setDraggedProducts({}));
          dispatch(setSelectedSnapshells([]));
        };
        return (
          <Product
            type="product"
            isDesktop={isDesktop}
            key={product.id}
            testId={`product-${index}`}
            innerRef={index == 0 ? currentElementProduct : null}
            primaryChoice={primaryChoice}
            clickHandler={clickHandler}
            onAddToBundle={clickHandler}
            product={product}
            index={index}
            isPopupState={isPopupState}
            additionalInformationIndex={additionalInformationIndex}
          />
        );
      })
    );

  return (
    <>
      <div className={classes.product} data-testid="product-menu">
        <div className={classes.product__row}>
          <div
            className={classNames(
              classes.product__rowColumn,
              classes.product__rowColumn_view,
              {
                [classes.product__rowColumn_view__bags]:
                  routesRequest[location.pathname] === "bags",
                [classes.product__rowColumn_view__opacity]: opacityAnimation,
                [classes.product__rowColumn_view__scrolled__tablet]: !visibleHeader,
              }
            )}
            data-testid="row-view-container"
            onClick={handleClickBundle}
          >
            <div
              className={classNames(classes.product__config, {
                [classes.product__config__hidden]: !visibleHeader,
              })}
              data-testid="container-header"
            >
              <HeaderProductMenu
                onShare={shareButtonHandler}
                onReset={openResetPopup}
                onEdit={handleButtonEdit}
                editing={state.isEditing}
                onResetBundle={resetHandler}
                loadingShare={loadingShare}
              />
            </div>
            <div
              ref={screenshot}
              className={classNames(classes.product__show, {
                [classes.product__showBelt]:
                  routesRequest[location.pathname] === "belts",
                [classes.product__showBag]:
                  routesRequest[location.pathname] === "bags",
                [classes.product__show__shrink]: !visibleHeader,
              })}
            >
              <div
                className={classNames(
                  "img__container",
                  classes.product__showImgContainer
                )}
                onMouseDown={mouseDownHandler}
              >
                {!primaryImage && (
                  <img
                    className={classes.product__showImgHint}
                    src={hintsImage[location.pathname]}
                    alt="hint"
                  />
                )}
                {primaryImage && location.pathname === "/KneePads" ? (
                  <>
                    <KneePads
                      id={primaryProduct?.id}
                      primaryImage={primaryImage}
                      products={selectedSnapshells}
                      setData={setKneePadData}
                      closeTooltip={closeTooltip}
                    />
                    <div ref={dropShapes} />
                  </>
                ) : (
                  <div className={classes.product__showImgContainerInner}>
                    {!isDesktop && (
                      <img
                        src={EyeIcon}
                        className={classNames({
                          [classes.product_eye_hidden]: visibleHeader,
                          [classes.product_eye]: !visibleHeader,
                        })}
                        alt="product"
                        data-testid="eye-icon"
                        onClick={handleScrollTop}
                      />
                    )}
                    {fixedImage && (
                      <div
                        ref={fixedImg}
                        style={{
                          bottom: `${handle?.bottom || 0}%`,
                          right: `${handle?.right || 0}%`,
                          left: `${handle?.left || 0}%`,
                        }}
                        className={classes.product__showImgBelt}
                      >
                        <img
                          src={fixedImage}
                          className={classNames(
                            classes.product__showImgBelt_img
                          )}
                          onClick={handleRemoveFixedMobile}
                          data-testid="fixed-image-container"
                          alt="product"
                        />
                        {state.isEditing && visibleHeader && (
                          <img
                            src={RemoveIcon}
                            onClick={handleRemoveFixed}
                            alt="Remove product"
                            className={classNames(
                              classes.product_removeItemFixed
                            )}
                          />
                        )}
                      </div>
                    )}
                    {primaryImage && (
                      <div className={classes.product__showImgBlock}>
                        <img
                          className={classNames(classes.product__showImg, {
                            [classes.product__showImg__scrolled]: !visibleHeader,
                          })}
                          src={primaryImage}
                          ref={primaryImg}
                          alt="product"
                          draggable={false}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        paddingLeft: !margins ? "inherit" : `${margins.left}%`,
                        paddingRight: !margins
                          ? "inherit"
                          : `${margins.right}%`,
                        top: !margins ? "inherit" : `${margins.top}%`,
                        left: primaryImg.current ? `50%` : "",
                        transform: primaryImg.current ? `translateX(-50%)` : "",
                      }}
                      className={classNames(classes.product__showDragList)}
                    >
                      <div
                        ref={dropShapes}
                        className={classNames(
                          classes.product__showDragListInner,
                          {
                            [classes.product__showDragListInner__hide__header]: !visibleHeader,
                          }
                        )}
                      >
                        {/* CELLS */}
                        {state.dropCells.map((cell, index) => (
                          <div
                            key={cell.id}
                            style={{
                              width: `calc(${100 / state.shapeCount}%)`,
                            }}
                            className={classNames(
                              classes.product__showDragItem,
                              {
                                [classes.product__showDragItem_unVisible]: !state.isDrag,
                                [classes.product__showDragItem_imageSet]: !state.isDrag,
                                [classes.product__showDragItem_active]:
                                  state.isDrag &&
                                  state.possibleToDrop[
                                    state.hoverIndex
                                  ]?.includes(index),
                              }
                            )}
                          >
                            {index !== state.dragItemIndex &&
                            cell &&
                            cell.url ? (
                              <div
                                ref={(ref) => {
                                  draggedProductImages.current[index] = ref;
                                }}
                                className={classNames(
                                  classes.product__showDragListInnerImg,
                                  {
                                    [classes.product__showDragListInnerImg__no__border__right]:
                                      state.dropCells[index + cell.size] &&
                                      state.dropCells[index + cell.size].url,
                                  }
                                )}
                                data-testid="cell-drop-img"
                                style={{
                                  width: `${100 * cell.size}%`,
                                }}
                                onClick={() => handleRemovePoucheMobile(index)}
                              >
                                {state.isEditing && visibleHeader && (
                                  <img
                                    src={RemoveIcon}
                                    onClick={() => handleRemove(index)}
                                    alt="Remove"
                                    className={classes.product_removeItem}
                                    data-testid="remove-icon-pouche"
                                  />
                                )}
                                <img
                                  src={cell.url}
                                  className={classNames(
                                    classes.product__image__cell
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                    <ProductBasketBar
                      items={basketBar}
                      editing={state.isEditing}
                      visibleHeader={visibleHeader}
                    />
                  </div>
                )}
              </div>
              {showHint ? (
                <div className={classes.hint}>Added product</div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              classes.product__rowColumn,
              classes.product__rowColumn_panel,
              ...(location.pathname === "/KneePads"
                ? [classes.product__rowColumnKneePad]
                : [])
            )}
          >
            <ProductsTabs
              items={getItems()}
              setActiveItem={setActiveStep}
              activeItem={activeStep}
              isImageSet={primaryImage}
            />
            <div
              className={classNames(classes.product__tab, {
                [classes.product__tab__shrink]: !visibleHeader,
              })}
            >
              <div className={classes.product__tabColumn}>
                <ul
                  ref={tabList}
                  data-testid="list-tabs"
                  className={classNames(classes.product__tabList, {
                    [classes.product__tabList__Loading]: loadingProducts,
                    [classes.product__tabList_isDrag]: state.isDrag,
                    [classes.product__tabList__shrink]: !visibleHeader,
                  })}
                >
                  {renderProducts()}
                  {renderPouches()}
                  {activeStep === 2
                    ? accessories.map((accessory, index) =>
                        accessory.position.includes("movable") ? (
                          <Product
                            type="accessory"
                            isDesktop={isDesktop}
                            key={accessory.id}
                            testId={`accessory-${index}`}
                            innerRef={
                              index == 0 ? currentElementAccesories : null
                            }
                            count={
                              state.productsCounts.find(
                                (e) => e.id === accessory.id
                              )?.count || 0
                            }
                            primaryChoice={null}
                            mouseDownHandler={(evt) => {
                              if (state.maxSpaceSize >= accessory.shapeSize) {
                                dispatchState({
                                  payload: {
                                    isDrag: true,
                                    point: {
                                      x: evt.clientX,
                                      y: evt.clientY,
                                    },
                                    dragItem: accessory,
                                    rect: dropShapes.current.getBoundingClientRect(),
                                  },
                                });
                              }
                            }}
                            touchStartHandler={(evt) => {
                              const {
                                changedTouches: [{ clientX: x, clientY: y }],
                              } = evt;
                              if (state.maxSpaceSize >= accessory.shapeSize) {
                                dispatchState({
                                  payload: {
                                    isDrag: false,
                                    isTouchStart: true,
                                    touchStartPoint: { x, y },
                                    point: { x, y },
                                    dragItem: accessory,
                                    rect: dropShapes.current.getBoundingClientRect(),
                                  },
                                });
                              }
                            }}
                            clickHandler={() => {
                              addToBasketBar(accessory);
                            }}
                            onAddToBundle={() => addToBasketBar(accessory)}
                            product={accessory}
                            index={index}
                            isPopupState={isPopupState}
                            additionalInformationIndex={
                              additionalInformationIndex
                            }
                            active={
                              state.isDrag &&
                              state.dragItemIndex === null &&
                              state.dragItem &&
                              state.dragItem.id === accessory.id
                            }
                          />
                        ) : (
                          <Product
                            type="accessory"
                            isDesktop={isDesktop}
                            key={accessory.id}
                            testId={`accessory-${index}`}
                            innerRef={
                              index == 0 ? currentElementAccesories : null
                            }
                            count={
                              state.productsCounts.find(
                                (e) => e.id === accessory.id
                              )?.count || 0
                            }
                            primaryChoice={null}
                            clickHandler={() => {
                              if (accessory.position.includes("slider")) {
                                addToBasketBar(accessory);
                              } else {
                                dispatch(
                                  setFixedProduct({ ...accessory, count: 1 })
                                );
                              }
                            }}
                            onAddToBundle={() => {
                              if (accessory.position.includes("slider")) {
                                addToBasketBar(accessory);
                              } else {
                                dispatch(
                                  setFixedProduct({ ...accessory, count: 1 })
                                );
                              }
                            }}
                            product={accessory}
                            index={index}
                            isPopupState={isPopupState}
                            additionalInformationIndex={
                              additionalInformationIndex
                            }
                            active={false}
                          />
                        )
                      )
                    : null}
                </ul>
              </div>
              <ProductsBasket
                buyNowHandler={buyNowHandler}
                drawCanvas={drawCanvas}
                loadingBuyNow={loadingBuyNow}
              />
            </div>
          </div>
        </div>
      </div>
      {!noTutorials && !passedAnimations[`${location.pathname}#${activeStep}`]
        ? animationComponents[`${location.pathname}#${activeStep}`]
        : null}
      {state.isDrag && state.dragItem && (
        <img
          ref={dragImg}
          className={classes.moveItem}
          style={{
            top: state.point.y,
            left: state.point.x,
            width: `${
              (state.rect.width / state.shapeCount) * state.dragItem.shapeSize
            }px`,
          }}
          draggable={false}
          src={state.dragItem.image.movable}
          alt="some"
        />
      )}
      {!noTutorials && tooltip !== -1 ? tooltips[tooltip] : null}
      {shareUrl ? (
        <ShareLink close={() => setShareUrl(null)} url={shareUrl} />
      ) : null}
      {state.resetPopup ? <Confirm submit={resetPopupHandler} /> : null}
    </>
  );
};

export default ProductsMenu;
