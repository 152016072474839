import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { BAGS } from "@constants/routes";
import RemoveIcon from "@assets/images/icon/remove.svg";
import { setBasketBarProducts } from "@redux/actions/productsActions";

import classes from "./productBasketBar.module.scss";

const ProductBasketBar = ({
  items,
  editing,
  visibleHeader,
  opacityAnimation,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isDesktop = useMediaQuery({ minWidth: 1281 });

  const handleRemove = (item, index) => {
    const newItems = [...items];
    newItems[index].count = item.count - 1;
    dispatch(setBasketBarProducts(newItems.filter((e) => e.count !== 0)));
  };

  const handleRemoveMobile = (item, index) => {
    if (!isDesktop && editing) {
      handleRemove(item, index);
    }
  };

  return (
    <div
      className={classNames(classes.containerWrapper, {
        [classes.containerWrapperBags]: location.pathname === BAGS,
        [classes.containerWrapperMinimalScrolledVersion]: !visibleHeader,
        [classes.containerWrapperOpacityAnimation]: opacityAnimation,
      })}
      data-testid="product-basket-bar"
    >
      <div className={classes.basketList} data-testid="basket-list">
        {items.map((item, index) => (
          <div
            key={item.id}
            className={classNames(classes.basketItem, {
              [classes.basketItemScrolledVersion]: !visibleHeader,
            })}
            onClick={() => handleRemoveMobile(item, index)}
          >
            <div
              data-testid="header"
              className={classNames(classes.header, {
                [classes.headerHidden]: !visibleHeader,
              })}
            >
              <div
                className={classNames(classes.basketQuantity, {
                  [classes.basketQuantity__scrolled]: !visibleHeader,
                })}
              >
                {item.count}
              </div>
              {editing && visibleHeader && (
                <img
                  src={RemoveIcon}
                  className={classes.basketItemRemove}
                  onClick={() => handleRemove(item, index)}
                  aria-label="delete button"
                  alt="Remove"
                />
              )}
            </div>

            <div
              className={classNames(classes.basketQuantityMobile, {
                [classes.basketQuantityMobileShow]: !visibleHeader,
              })}
            >
              {item.count}
            </div>

            <img
              className={classNames(classes.basketImg, {
                [classes.basketImgScrolledVersion]: !visibleHeader,
              })}
              src={item.product.image.primary}
              alt="product quantity"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

ProductBasketBar.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
  editing: PropTypes.bool,
  visibleHeader: PropTypes.bool,
  opacityAnimation: PropTypes.bool,
};

ProductBasketBar.defaultProps = {};

export default ProductBasketBar;
