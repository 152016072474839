import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import PopupWrapper from "@components/common/popupWrapper/popupWrapper";
import classes from "./confirm.module.scss";

const Confirm = ({ submit }) => {
  const confirm = React.useCallback(() => {
    submit(true);
  }, [submit]);

  const cancel = React.useCallback(() => {
    submit(false);
  }, [submit]);

  return (
    <PopupWrapper
      afterClose={cancel}
      innerPopupClass={classes.innerPopupClasses}
    >
      <div className={classes.desContainer}>
        <div className={classes.desc}>Do you want to reset your items?</div>
      </div>

      <form>
        <div className={classes.accept}>
          <button
            className={classNames(classes.acceptBtn, classes.acceptBtnCancel)}
            onClick={cancel}
            type="button"
          >
            No
          </button>
          <button
            className={classNames(classes.acceptBtn, classes.acceptBtnEnter)}
            onClick={confirm}
            type="button"
          >
            yes
          </button>
        </div>
      </form>
    </PopupWrapper>
  );
};

Confirm.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default Confirm;
