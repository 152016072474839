import React from "react";
import PropTypes from "prop-types";

import classes from "./customButton.module.scss";

const CustomButton = ({ onClick, label, width, height }) => {
  return (
    <div
      className={classes.container}
      onClick={onClick}
      style={{
        width: width ? width : "100%",
        height: height ? height : "50px",
      }}
    >
      {label}
    </div>
  );
};

CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default CustomButton;
