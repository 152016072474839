import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const instance = axios.create({
  baseURL: apiUrl,
});

export function getProducts() {
  return instance.get("products");
}

export function shareState(state, url) {
  return instance.post("share", { state, url }).then(({ data }) => data);
}

export function getState(hash) {
  return instance
    .get("shared-state", { params: { hash } })
    .then(({ data }) => data);
}

export function getBundle(bundleId) {
  return instance.get(`bundle/${bundleId}`).then(({ data }) => data);
}

export function buyNow(state, url, bundleId, redirectUrl) {
  return instance
    .post(`buy-now`, { state, url, bundleId, redirectUrl })
    .then(({ data }) => data);
}

export function verifyToken(token) {
  return instance
    .post("verify-token", { token })
    .then(() => true)
    .catch(() => false);
}

export function sendShareEmail(message) {
  return instance.post("share/email", message);
}
