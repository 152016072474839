import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import classes from './kneePads.module.scss'
import { setSelectedSnapshells } from '../../redux/actions/productsActions'

const margins = {
  7: {
    l: 0.47,
    w: 0.62,
    t: 0.25,
    b: 0.05,
  },
  8: {
    l: 0.4,
    w: 0.85,
    t: 0.01,
    b: 0.03,
  },
}

function getImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.crossOrigin = 'Anonymous'
    img.addEventListener('load', () => {
      resolve(img)
    })
    img.addEventListener('error', reject)
  })
}


const KneePads = ({ id, primaryImage, products, setData, closeTooltip }) => {
  const dispatch = useDispatch()
  const [primary, setPrimary] = React.useState(null)
  const [drag, setDrag] = React.useState(null)
  const [coordinate, setCoordinate] = React.useState({ x: 0, y: 0, width: 0, height: 0, offsetX: 0, offsetY: 0 })
  const [trashHover, setTrashHover] = React.useState(false)

  const trash = React.useRef(null)
  const wrapper = React.useRef(null)


  const stats = React.useMemo(() => {
    const wrapperRect = wrapper.current?.getBoundingClientRect()
    const primaryRect = wrapperRect && primary && {
      height: Math.min(wrapperRect.height, 413),
    }

    if (!wrapperRect || !primaryRect) {
      setData(null)
      return null
    }

    primaryRect.width = primary.width / primary.height * primaryRect.height

    const { l, w, t, b } = margins[id]
    const width = Math.max(l + w * ((products.length - 1) / 2 + 1), 1)


    if (wrapperRect.width < primaryRect.width * width) {
      primaryRect.width = wrapperRect.width / width
      primaryRect.height = primary.height / primary.width * primaryRect.width
    }

    setData({
      size: {
        width: primaryRect.width * width,
        height: primaryRect.height,
      },
      info: {primaryRect, margins: {l, w, t, b}},
      items: [
        { src: primaryImage, x: 0, y: 0, ...primaryRect },
        ...products.map((product, index) => ({
          src: product.image.movable,
          x: primaryRect.width * (l + index * w / 2),
          y: primaryRect.height * t,
          width: primaryRect.width * w,
          height: primaryRect.height * (1 - t - b),
        })),
      ],
    })

    return {
      primary: {
        top: wrapperRect.height / 2 - primaryRect.height / 2,
        left: wrapperRect.width / 2 - primaryRect.width * width / 2,
        bottom: wrapperRect.height / 2 - primaryRect.height / 2,
      },
      products: {
        top: wrapperRect.height / 2 - primaryRect.height / 2 + primaryRect.height * t,
        bottom: wrapperRect.height / 2 - primaryRect.height / 2 + primaryRect.height * b,
        left: wrapperRect.width / 2 - primaryRect.width * (width / 2 - l),
        width: primaryRect.width * w / 2,
        height: primaryRect.height * (1 - t - b),
      },
      wrapperRect,
    }
  }, [
    wrapper.current?.getBoundingClientRect(),
    primary,
    products,
    id,
    setData,
  ])

  const moveHandler = React.useCallback((evt) => {
    if (evt.type === 'touchmove') {
      setCoordinate(state => ({
        ...state,
        x: evt.targetTouches[0].clientX,
        y: evt.targetTouches[0].clientY,
      }))
    } else {
      setCoordinate(state => ({
        ...state,
        x: evt.x,
        y: evt.y,
      }))
    }
  }, [])

  const moveEnd = React.useCallback(() => {
    if (trashHover) {
      dispatch(
        setSelectedSnapshells(products.filter(product => product.id !== drag?.id)),
      )
    }
    setDrag(null)
    setTrashHover(false)
  }, [drag, trashHover])

  React.useEffect(() => {
    getImage(primaryImage)
      .then(({ width, height }) => setPrimary({ width, height }))
      .catch(() => setPrimary(null))
  }, [primaryImage])

  React.useEffect(() => {
    if (drag) {
      window.addEventListener('mousemove', moveHandler)
      window.addEventListener('mouseup', moveEnd)
    }

    return () => {
      window.removeEventListener('mousemove', moveHandler)
      window.removeEventListener('mouseup', moveEnd)
    }
  }, [drag, moveHandler, moveEnd])

  React.useEffect(() => {
    document.addEventListener('touchmove', moveHandler)
    document.addEventListener('touchend', moveEnd)
    return () => {
      document.removeEventListener('touchmove', moveHandler)
      document.removeEventListener('touchend', moveEnd)
    }
  }, [moveHandler, moveEnd])

  React.useEffect(() => {
    const rect = trash.current?.getBoundingClientRect()
    if (rect) {
      setTrashHover(rect.x <= coordinate.x && rect.y <= coordinate.y && rect.right >= coordinate.x && rect.bottom >= coordinate.y)
    }
  }, [trash.current, coordinate.x, coordinate.y])

  return (
    <div
      className={classes.dragContainer}
    >
      <div
        ref={wrapper}
        className={classes.dragBlock}
      >
        <ul className={classes.dragList}>
          {!primaryImage ? null : (
            <li
              className={classes.dragItem}
              style={stats ? {
                left: `${stats.primary.left}px`,
                top: `${stats.primary.top}px`,
                bottom: `${stats.primary.bottom}px`,
              } : { top: '-100%', left: '-100%' }}
            >
              <img className={classes.dragImg} src={primaryImage} alt='drag product' />
            </li>
          )}
          {products.map((product, index) => (
            <li
              key={product.id}
              className={classes.dragItem}
              style={stats ? {
                ...drag?.id === product.id ? {
                  top: 0,
                  left: 0,
                  transform: `translate(${
                    coordinate.x - coordinate.offsetX - stats.wrapperRect.x
                  }px, ${
                    coordinate.y - coordinate.offsetY - stats.wrapperRect.y
                  }px)`,
                  width: `${coordinate.width}px`,
                  height: `${coordinate.height}px`,
                  ...(trashHover ? { opacity: 0.5 } : { opacity: 1 }),
                } : {
                  top: `${stats?.products.top}px`,
                  bottom: `${stats?.products.bottom}px`,
                  left: `${(stats?.products.left + stats?.products.width * index)}px`,
                },
              } : { top: '-100%', left: '-100%' }}
              onMouseDown={(evt) => {
                closeTooltip(1)
                const rect = evt.target?.getBoundingClientRect()
                evt.preventDefault()
                setDrag(product)
                setCoordinate({
                  ...coordinate,
                  x: evt.clientX,
                  y: evt.clientY,
                  offsetX: evt.clientX - rect.x,
                  offsetY: evt.clientY - rect.y,
                  width: rect.width,
                  height: rect.height,
                })
              }}
              onTouchStart={(evt) => {
                closeTooltip(1)
                const rect = evt.target?.getBoundingClientRect()
                setDrag(product)
                setCoordinate({
                  ...coordinate,
                  x: evt?.targetTouches[0].clientX,
                  y: evt?.targetTouches[0].clientY,
                  offsetX: evt?.targetTouches[0].clientX - rect.x,
                  offsetY: evt?.targetTouches[0].clientY - rect.y,
                  width: rect.width,
                  height: rect.height,
                })
              }}
            >
              <img className={classes.dragImg} src={product.image.movable} alt='drag product' />
            </li>
          ))}
        </ul>
        <div
          ref={trash}
          className={classNames(
            classes.product__showImgTrash, {
              [classes.product__showImgTrash_inactive]:
                !drag,
            },
          )}
        >
          <svg
            className={classes.product__showImgTrashIcon}
            xmlns='http://www.w3.org/2000/svg'
            width='35'
            height='35'
            viewBox='0 0 28 29.4'
          >
            <path
              d='M10,1.6h7A1.4,1.4,0,0,1,18.4,3V4.4H8.6V3A1.4,1.4,0,0,1,10,1.6ZM7,4.4V3a3,3,0,0,1,3-3h7a3,3,0,0,1,3,3V4.4h8V6H24.5V25.4a4,4,0,0,1-4,4H7.5a4,4,0,0,1-4-4V6H0V4.4Zm2.2,19v-13h1.6v13Zm7.5,0v-13h1.6v13ZM22.9,6H5.1V25.4a2.39,2.39,0,0,0,2.4,2.4h13a2.39,2.39,0,0,0,2.4-2.4Z'
              fillRule='evenodd'
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

KneePads.propTypes = {
  id: PropTypes.number,
  closeTooltip: PropTypes.func,
  primaryImage: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  setData: PropTypes.func.isRequired,
}
KneePads.defaultProps = {
  primaryImage: null,
  id: 7,
  products: [],
  closeTooltip: () => {}
}

export default React.memo(KneePads)
