import PropTypes from "prop-types";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";

import { Loader } from "@components/loader/loader";
import useComponentVisible from "@hooks/useComponentVisible";
import ArrowBlackDown from "@assets/images/icon/icon_up.svg";
import ArrowGrayUp from "@assets/images/icon/arrow-up-gray.svg";
import ArrowBlackUp from "@assets/images/icon/arrow-bottom.svg";
import ArrowGrayDown from "@assets/images/icon/arrow-down-gray.svg";

import TypeItem from "./typeItem";
import LoaderAnimation from "./loaderAnimation";
import classes from "./selectProductType.module.scss";

const SelectProductType = ({ items, onChange, selected, loading }) => {
  const [label, setLabel] = useState("BELT");
  const [showAnimation, setShowAnimation] = useState(true);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [excludedItems, setExcludedItems] = useState([]);
  const {
    ref,
    auxRef,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const handleSelectItem = (id) => {
    onChange(id);
    setIsComponentVisible(false);
  };

  const toggleOpen = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const getIcon = () => {
    if (isDesktop) {
      return isComponentVisible ? ArrowGrayUp : ArrowGrayDown;
    }

    return isComponentVisible ? ArrowBlackDown : ArrowBlackUp;
  };

  const handleFinishAnimation = () => setShowAnimation(false);

  useEffect(() => {
    if (items && selected) {
      const item = items.find((i) => i.id === selected);
      const excluded = items.filter((i) => i.id !== selected);
      setLabel(item.name);
      setExcludedItems(excluded);
    }
  }, [items, selected]);

  return (
    <div className={classes.dropdown} data-testid="select-product-type">
      <div className={classes.row}>
        <h1 className={classes.title}>Build your own</h1>
        {showAnimation ? (
          <LoaderAnimation onFinish={handleFinishAnimation} />
        ) : (
          <div
            onClick={toggleOpen}
            ref={auxRef}
            className={classes.productTypeLabel}
            data-testid="label-selected"
          >
            {label}{" "}
            {loading || !items ? (
              <Loader type="dropdown" testId="header-loader" />
            ) : (
              <img
                className={classes.arrow}
                src={getIcon()}
                alt="Product types"
              />
            )}
          </div>
        )}
      </div>

      <ul
        className={classNames(classes.dropdownContent, {
          [classes.hideContent]: !isComponentVisible,
        })}
        data-testid="option-list"
        ref={ref}
      >
        {excludedItems.map((item) => (
          <TypeItem
            key={item.id}
            {...item}
            onSelect={handleSelectItem}
            visible={isComponentVisible}
          />
        ))}
      </ul>
    </div>
  );
};

SelectProductType.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  selected: PropTypes.number,
};

export default SelectProductType;
