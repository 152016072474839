import React from "react";
import PropTypes from "prop-types";

import classes from "./selectProductType.module.scss";
import classNames from "classnames";

const TypeItem = ({ id, name, onSelect, visible }) => {
  return (
    <li
      className={classNames(
        classes.productTypeLabel,
        classes.productTypeOption,
        {
          [classes.productTypeLabelHide]: !visible,
        }
      )}
      onClick={() => onSelect(id)}
    >
      <span>{name}</span>
    </li>
  );
};

TypeItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  visible: PropTypes.bool,
};

export default TypeItem;
