import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useState } from "react";
import StarRatingComponent from "react-star-rating-component";

import HeartIcon from "@assets/images/icon/heart.svg";
import ShareIcon from "@assets/images/icon/share-black.svg";
import CustomButton from "@components/customButton/customButton";
import PopupWrapper from "@components/common/popupWrapper/popupWrapper";

import Carousel from "./carousel";
import classes from "./productInformation.module.scss";

const ProductInformation = ({
  name,
  model,
  image,
  images,
  description,
  straps,
  dimensions,
  weight,
  warranty,
  price,
  onAddToBundle,
  isDesktop,
}) => {
  const [descriptionEllipsis, setDescriptionEllisis] = useState(true);
  const [visible, setVisible] = useState(true);

  const hanleAddToBundle = (evt) => {
    evt.stopPropagation();
    onAddToBundle();
    setVisible(false);
  };

  const handleScroll = () => {
    if (descriptionEllipsis) {
      setDescriptionEllisis(false);
    }
  };

  return (
    <PopupWrapper
      innerPopupClass={classes.innerPopupClasses}
      visibleOut={visible}
    >
      <div className={classes.information__name_mobile}>{name || model}</div>
      <div className={classes.information__column}>
        <Carousel images={images} additional={image.additional} />
      </div>
      <div className={classes.information__column}>
        <div className={classes.information__code_stars}>
          <div className={classes.information__code}>{model}</div>
          <StarRatingComponent
            starColor="#F7AF15"
            emptyStarColor="#B3B3B3"
            value={0}
            editing={false}
            name="rate"
          />
          <div className={classes.information__rate}>(0)</div>
        </div>
        <div
          className={classNames(classes.information__description__container, {
            [classes.information__description__container__overflow]: !descriptionEllipsis,
          })}
          onTouchMove={handleScroll}
          onScroll={handleScroll}
          data-testid="container-info"
        >
          <div className={classes.information__description__info}>
            <div className={classes.information__rowColumnTitle}>
              {name || model}
            </div>
            <div
              className={classNames({
                [classes.information__ellipsis__text]: descriptionEllipsis,
              })}
            >
              <div
                className={classes.information__desc}
                data-testid="information-description"
              >
                {description.split("\n").map((desc) => (
                  <div key={desc} className={classes.information__descItem}>
                    {desc}
                  </div>
                ))}
              </div>
              <div className={classes.information__attribute}>
                <ul className={classes.information__attributeList}>
                  <li className={classes.information__attributeItem}>
                    <div className={classes.information__attributeName}>
                      Straps
                    </div>
                    <div className={classes.information__attributeValue}>
                      <div className={classes.information__attributeValueItem}>
                        {straps}
                      </div>
                    </div>
                  </li>
                  <li
                    className={classNames(
                      classes.information__attributeItem,
                      classes.information__attributeItemColumn
                    )}
                  >
                    <div className={classes.information__attributeName}>
                      DIMENSIONS - WEB
                    </div>
                    <div className={classes.information__attributeValue}>
                      <div className={classes.information__attributeValueItem}>
                        {dimensions}
                      </div>
                    </div>
                  </li>
                  <li
                    className={classNames(
                      classes.information__attributeItem,
                      classes.information__attributeItemColumn
                    )}
                  >
                    <div className={classes.information__attributeName}>
                      WEIGHT - WEB
                    </div>
                    <div className={classes.information__attributeValue}>
                      <div className={classes.information__attributeValueItem}>
                        <div>{weight}</div>
                      </div>
                    </div>
                  </li>
                  <li className={classes.information__attributeItem}>
                    <div className={classes.information__attributeName}>
                      FITTING SIZE
                    </div>
                    <div className={classes.information__attributeValue}>
                      <div className={classes.information__attributeValueItem}>
                        small
                      </div>
                    </div>
                  </li>
                  <li className={classes.information__attributeItem}>
                    <div className={classes.information__attributeName}>
                      WARRANTY
                    </div>
                    <div className={classes.information__attributeValue}>
                      <div className={classes.information__attributeValueItem}>
                        {warranty}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {descriptionEllipsis && (
            <div
              className={classes.information__transparent}
              data-testid="transparent-div"
            />
          )}
        </div>
        <div className={classes.information__price_share}>
          <span className={classes.information__price}>{`$${price}`}</span>
          <div className={classes.information__share}>
            <div className={classes.information__share__icon}>
              <img src={ShareIcon} alt="Share" />
            </div>
            <div className={classes.information__share__icon}>
              <img src={HeartIcon} alt="Add to favourite" />
            </div>
          </div>
        </div>
        <div className={classes.information__price__button}>
          <span className={classes.information__price}>{`$${price}`}</span>
          <CustomButton
            onClick={hanleAddToBundle}
            label="ADD TO BUNDLE"
            width={isDesktop ? "322px" : "181px"}
            height={isDesktop ? "50px" : "44px"}
          />
        </div>
      </div>
    </PopupWrapper>
  );
};

ProductInformation.propTypes = {
  name: PropTypes.string,
  model: PropTypes.string.isRequired,
  image: PropTypes.shape({ additional: PropTypes.string }).isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string.isRequired,
  straps: PropTypes.string.isRequired,
  dimensions: PropTypes.string.isRequired,
  weight: PropTypes.string.isRequired,
  warranty: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  onAddToBundle: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool,
};

export default ProductInformation;
