import React from 'react'
import { useDispatch } from 'react-redux'
import classes from './animation.module.scss'
import { hideAnimation } from '../../redux/actions/animateActions'

// eslint-disable-next-line react/prop-types
const Animation = ({ onClose, children }) => {
  const dispatch = useDispatch()
  let hideAnimationTimeOut

  const closeAnimation = () => {
    dispatch(hideAnimation())

    clearTimeout(hideAnimationTimeOut)

    if (onClose) {
      onClose()
    }
  }

  const keyEventDetect = (evt) => {
    if (evt.key === 'Escape') {
      closeAnimation()
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', keyEventDetect)
    window.addEventListener('keyup', keyEventDetect)

    hideAnimationTimeOut = setTimeout(closeAnimation, 5000)

    return () => {
      window.removeEventListener('keydown', keyEventDetect)
      window.removeEventListener('keyup', keyEventDetect)
    }
  }, [])

  return (
    <div className={classes.animation}>
      {children}
      <button
        type="button"
        className={classes.animation__hide}
        onClick={closeAnimation}
      >
        <span>Close button</span>
      </button>
    </div>
  )
}

export default Animation
