import { combineReducers } from 'redux'

import { productsReducer, initialState as productInitialState } from './productsReducer'
import { animateReducer, initialState as animateInitialState } from './animateReducer'

const rootReducer = combineReducers({
  products: productsReducer,
  animate: animateReducer
})

export const initalState = {
  products: productInitialState,
  animate: animateInitialState
}

export default rootReducer
