import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";

import { sendShareEmail } from "@api/api";
import { Loader } from "@components/loader/loader";
import PopupWrapper from "@components/common/popupWrapper/popupWrapper";

import classes from "./shareLink.module.scss";

const ShareLink = ({ close, url }) => {
  const [showHint, setShowHint] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState(0);
  const [hintMessage, setHintMessage] = React.useState("link copied");
  const [loading, setLoading] = React.useState(false);

  const copyUrl = React.useCallback(() => {
    const result = copy(url);
    clearTimeout(timeoutId);
    if (result) {
      setHintMessage("link copied");
      setTimeoutId(setTimeout(setShowHint, 3000, false));
    }
    setShowHint(result);
  }, [url]);

  const sendEmail = async (evt) => {
    evt.preventDefault();
    setLoading(true);

    const hash = url.replace("&tutorials=no", "").split("hash=")[1];

    const message = {
      designUrl: url,
      hash,
      from: evt.target.from.value,
      to: evt.target.to.value,
    };

    try {
      await sendShareEmail(message);
      setHintMessage("Email sent successfully");
      setShowHint(true);
    } catch (err) {
      setHintMessage("Error sending email, please try again later");
      setShowHint(true);
    } finally {
      setLoading(false);
      clearTimeout(timeoutId);
      setTimeoutId(setTimeout(setShowHint, 3000, false));
    }
  };

  return (
    <PopupWrapper
      innerPopupClass={classes.innerPopupClasses}
      afterClose={close}
    >
      <div className={classes.caption}>Share Link</div>
      <form
        data-testid="share-link"
        className={classes.share}
        onSubmit={sendEmail}
      >
        <div className={classes.shareRow}>
          <input
            className={classes.shareInput}
            type="text"
            placeholder="Email Address *"
            required={true}
            name="from"
            data-testid="from"
          />
        </div>
        <div className={classes.shareRow}>
          <input
            className={classes.shareInput}
            type="text"
            placeholder="Friends’s Email *"
            required={true}
            name="to"
            data-testid="to"
          />
        </div>
        <div className={classNames(classes.shareRow, classes.shareRowButton)}>
          <button className={classes.shareAccept} type="submit">
            {loading ? <Loader type="button" /> : "Send Link"}
          </button>
        </div>
      </form>
      <div className={classes.copy}>
        <button className={classes.copyBtn} type="button" onClick={copyUrl}>
          Copy link
        </button>
      </div>
      {showHint ? (
        <div className={classNames(classes.hint, classes.hintShow)}>
          {hintMessage}
        </div>
      ) : null}
    </PopupWrapper>
  );
};

ShareLink.propTypes = {
  url: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

ShareLink.defaultProps = {};
export default ShareLink;
