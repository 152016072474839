import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import classes from "./loader.module.scss";

export const Loader = ({ type, testId }) => {
  const variant = {
    dropdown: classes.dropdown,
    products: classes.products,
    button: classes.button,
    headerButton: classes.headerButton,
  };
  return (
    <span
      data-testid={testId}
      className={classNames(classes.loader, variant[type])}
    />
  );
};

Loader.defaultProps = {
  type: "button",
  testId: "default-loader",
};

Loader.propTypes = {
  type: PropTypes.oneOf(["dropdown", "products", "button", "headerButton"]),
  testId: PropTypes.string,
};
