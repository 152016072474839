import React from "react";
import { useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
import { closePopup } from "@redux/actions/productsActions";

import classes from "./popupWrapper.module.scss";

// eslint-disable-next-line react/prop-types
const PopupWrapper = ({
  children,
  innerPopupClass,
  afterClose,
  visibleOut = true,
}) => {
  const closeButton = React.useRef(null);
  const dispatch = useDispatch();
  const closeClickWrapper = () => {
    dispatch(closePopup());
    afterClose();
  };

  const keyEventDetect = (evt) => {
    if (evt.key === "Escape") {
      closeClickWrapper();
    }

    if (evt.key === "Tab") {
      evt.preventDefault();
      closeButton.current.focus();
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", keyEventDetect);
    window.addEventListener("keyup", keyEventDetect);

    return () => {
      window.removeEventListener("keydown", keyEventDetect);
      window.removeEventListener("keyup", keyEventDetect);
    };
  }, []);

  React.useEffect(() => {
    if (!visibleOut) {
      closeClickWrapper();
    }
  }, [visibleOut]);

  return (
    <div className={classes.wrapper} data-testid="popupwrapper">
      <button
        type="button"
        aria-label="close wrapper"
        onClick={closeClickWrapper}
        className={classes.wrapper__wrapperLikeButton}
        data-testid="button-close-1"
      />
      <div className={innerPopupClass}>
        <button
          type="button"
          aria-label="close wrapper"
          onClick={closeClickWrapper}
          tabIndex="0"
          ref={closeButton}
          className="btn__close"
          data-testid="button-close-2"
        />
        {children}
      </div>
    </div>
  );
};

PopupWrapper.propTypes = {
  afterClose: PropTypes.func,
  visibleOut: PropTypes.bool,
};

PopupWrapper.defaultProps = {
  afterClose: () => null,
};

export default PopupWrapper;
