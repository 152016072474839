import React from 'react'
import { useDispatch } from 'react-redux'
import Animation from '../animation'
import classes from './bag.module.scss'
import Product from '../../../assets/images/animation/bag/bag.svg'
import Item1 from '../../../assets/images/animation/bag/pouches1.png'
import Item2 from '../../../assets/images/animation/bag/pouches3.png'
import {skipAnimations} from '../../../redux/actions/animateActions';

const Bag = () => {
  const dispatch = useDispatch()
  return (
    <Animation onClose={() => dispatch(skipAnimations(['/Bags#0']))}>
      <div className={classes.container}>
        <h2 className={classes.caption}>
          <span>Build Your Own</span>
          <strong>Tool Bag</strong>
        </h2>
        <div className={classes.img}>
          <div className="img__container">
            <img className={classes.img__product} src={Product} alt="Product" />
          </div>
          <div className={classes.img__1}>
            <div className="img__container">
              <img
                className={classes.img__item}
                src={Item1}
                alt="Product item"
              />
            </div>
          </div>
          <div className={classes.img__2}>
            <div className="img__container">
              <img
                className={classes.img__item}
                src={Item2}
                alt="Product item"
              />
            </div>
          </div>
        </div>
      </div>
    </Animation>
  )
}

export default Bag;
