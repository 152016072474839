import React from "react";
import "./App.scss";
import { useDispatch } from "react-redux";
import ProductsMenu from "./components/productsMenu/productsMenu";
import { skipAnimations } from "./redux/actions/animateActions";

function App() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      skipAnimations(
        JSON.parse(localStorage.getItem("passedAnimations") || "[]")
      )
    );
  }, []);
  return (
    <div className="App wrapper-outer">
      <main className="main wrapper">
        <ProductsMenu />
      </main>
    </div>
  );
}

export default App;
