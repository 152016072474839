import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  addNewBasketItem,
  setChoosingStep,
} from "@redux/actions/productsActions";
import { instance as api } from "@api/api";
import Edit from "@assets/images/icon/edit-black.svg";
import Share from "@assets/images/icon/share-black.svg";
import Trash from "@assets/images/icon/delete-black.svg";

import { routeValues } from "./constants";
import ConfirmAddToBundle from "./confirmAddToBundle";
import classes from "./headerProductMenu.module.scss";
import SelectProductType from "./components/selectProductType/selectProductType";
import CustomButtonHeader from "./components/customButtonHeader/customButtonHeader";

const HeaderProductMenu = ({
  onReset,
  onShare,
  onEdit,
  editing,
  onResetBundle,
  loadingShare,
}) => {
  const [items, setItems] = useState(null);
  const [selectedType, setSelectedType] = useState();
  const [addBundleType, setAddBundleType] = useState();
  const [showPopupAddToBundle, setShowPopupAddToBundle] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { primaryImage } = useSelector(
    ({ products }) => products.basketList[products.activeIndex]
  );
  const dispatch = useDispatch();

  const handleChangeSelect = (productTypeId) => {
    if (primaryImage) {
      setAddBundleType(productTypeId);
      setShowPopupAddToBundle(true);
      return;
    }
    let route = routeValues[productTypeId];
    setSelectedType(productTypeId);
    if (!route) {
      route = routeValues[1];
    }

    history.push(route);
  };

  const getProducTypes = async () => {
    try {
      setLoading(true);
      const response = await api.get("/product-type");
      const types = response.data.productTypes;
      if (types) {
        setItems(types);
        const route = Object.keys(routeValues).find(
          (key) => routeValues[key] === location.pathname
        );

        setSelectedType(route ? Number(route) : types[0].id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setShowPopupAddToBundle(false);

  const handleAddToBundle = () => {
    let route = routeValues[addBundleType];
    dispatch(addNewBasketItem(route));
    dispatch(setChoosingStep(0));

    setShowPopupAddToBundle(false);
    setSelectedType(addBundleType);
    history.push(route);
  };

  const handleReset = () => {
    let route = routeValues[addBundleType];
    onResetBundle();
    handleClose();
    setSelectedType(addBundleType);
    history.push(route);
  };

  useEffect(() => {
    const getItems = async () => {
      getProducTypes();
    };

    getItems();
  }, []);

  return (
    <>
      <div className={classes.menuContainer} data-testid="header-product-menu">
        <div className={classes.mediumRow}>
          <SelectProductType
            items={items}
            onChange={handleChangeSelect}
            selected={selectedType}
            loading={loading}
          />
        </div>
        <div className={classNames(classes.mediumRow, classes.buttonsRow)}>
          <CustomButtonHeader
            loading={loadingShare}
            label="SHARE"
            icon={Share}
            onClick={onShare}
          />
          <CustomButtonHeader label="RESET" icon={Trash} onClick={onReset} />
          <CustomButtonHeader
            label="EDIT"
            icon={Edit}
            onClick={onEdit}
            editing={editing}
          />
        </div>
      </div>
      {showPopupAddToBundle && (
        <ConfirmAddToBundle
          onClose={handleClose}
          onCancel={handleClose}
          onDiscard={handleReset}
          onAddToCart={handleAddToBundle}
        />
      )}
    </>
  );
};

HeaderProductMenu.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  onResetBundle: PropTypes.func.isRequired,
  loadingShare: PropTypes.bool,
};

export default HeaderProductMenu;
