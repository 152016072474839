import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'


const composeEnhancers = composeWithDevTools({ compose });

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store
