import React from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import Animation from '../animation'
import classes from './beltDragAnimation.module.scss'
import Product from '../../../assets/images/animation/belt/belt.png'

import Cursor from '../../../assets/images/icon/cursor.svg'
import KindItem1 from '../../../assets/images/animationDrag/bag/pouches1.svg'
import KindItem2 from '../../../assets/images/animationDrag/bag/pouches2.svg'
import { skipAnimations } from '../../../redux/actions/animateActions'

const BeltDragAnimation = () => {
  const dispatch = useDispatch()

  return (
    <Animation onClose={() => dispatch(skipAnimations(['/Belts#1']))}>
      <div className={classes.container}>
        <h2 className={classes.caption}>
          <div
            className={classNames(
              classes.caption__item,
              classes.caption__item1
            )}
          >
            <strong>Drag and Drop</strong>
            <span>products</span>
          </div>
          <div
            className={classNames(
              classes.caption__item,
              classes.caption__item2
            )}
          >
            <strong>Change the position</strong>
          </div>
          <div
            className={classNames(
              classes.caption__item,
              classes.caption__item3
            )}
          >
            <strong>remove products</strong>
          </div>
        </h2>
        <div className={classes.containerInner}>
          <div className={classes.img}>
            <div className="img__container">
              <img
                className={classes.img__product}
                src={Product}
                alt="Product"
              />
              <ul className={classes.hint}>
                <li className={classes.hintItem} />
                <li className={classes.hintItem} />
                <li className={classes.hintItem} />
                <li className={classes.hintItem} />
                <li className={classes.hintItem} />
              </ul>
              <div className={classes.hintProduct} />
              <div className={classes.hintTrash} />
            </div>
            <div className={classes.img__1}>
              <div className="img__container">
                {/* <img className={classes.img__item} src={Item1} alt="Product item" /> */}
              </div>
            </div>
          </div>
          <div className={classes.cursor}>
            <div className={classes.cursorHint}>
              <div className="img__container">
                <img
                  className={classes.cursorHintImg}
                  src={Cursor}
                  alt="icon of cursor"
                />
              </div>
            </div>
          </div>
          <div className={classes.kind}>
            <ul className={classes.kind__list}>
              <li className={classes.kind__listItem}>
                <div className="img__container">
                  <img
                    className={classes.kind__listItemImg}
                    src={KindItem1}
                    alt="kind"
                  />
                </div>
              </li>
              <li className={classes.kind__listItem}>
                <div className="img__container">
                  <img
                    className={classes.kind__listItemImg}
                    src={KindItem2}
                    alt="kind"
                  />
                </div>
              </li>
              <li className={classes.kind__listItem}>
                <div className="img__container">
                  <img
                    className={classes.kind__listItemImg}
                    src={KindItem1}
                    alt="kind"
                  />
                </div>
              </li>
              <li className={classes.kind__listItem}>
                <div className="img__container">
                  <img
                    className={classes.kind__listItemImg}
                    src={KindItem2}
                    alt="kind"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Animation>
  )
}

export default BeltDragAnimation;
