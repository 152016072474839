import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classes from "./selectProductType.module.scss";

const animationValues = ["", "BELT", "BAGS", "KNEE PAD", "GARAGE"];

const LoaderAnimation = ({ onFinish }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    animationValues.forEach((_, index) => {
      setTimeout(() => setPosition(index), 300 * (index + 1));
    });
    setTimeout(() => onFinish(), 300 * (animationValues.length + 1));
  }, []);

  return (
    <div data-testid="loader-animation" className={classes.productTypeLabel}>
      {animationValues[position]}
    </div>
  );
};

LoaderAnimation.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

export default LoaderAnimation;
